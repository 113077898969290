<template>
    <v-dialog v-model="value" max-width="650px">
  <v-card>
    <v-toolbar flat color="secondary" dark>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
      <div class="text-h6 my-5">Your new backup repository is ready to use! 🎉</div>
      <p>
        Access it using the URL below, get custom
        <router-link :to="{ name: 'Setup', params: { repoId: activeRepo.id } }">
          setup instructions
        </router-link>
        or read our <a href="https://docs.borgbase.com/" target="_blank">docs</a
        ><v-icon x-small right>fa-external-link-alt</v-icon> on how to get started on
        different platforms.
      </p>
      <p class="text-md-center mt-6">
        <code>{{ activeRepo.repoPath }}</code>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="ma-0" @click="copyPath(activeRepo.repoPath)">
              <v-icon color="grey" small>fa-copy</v-icon>
            </v-btn>
          </template>
          Click to copy repo path
        </v-tooltip>
      </p>
        </v-col>
      </v-row>
    </v-container>
    <v-divider light></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark depressed @click="$emit('input', false)"
        >Close</v-btn
      >
    </v-card-actions>
  </v-card>
    </v-dialog>
</template>

<script>
import copy from "copy-to-clipboard";
export default {
  name: "RepoAddSuccessDialog",
  props: ["newRepoId", "value"],
  data() {
    return {
      title: "Repository Added"
    };
  },
  computed: {
    activeRepo() {
      return this.$store.getters["repos/getSingle"](this.newRepoId);
    }
  },
  methods: {
    copyPath(url) {
      copy(url);
    }
  }
};
</script>
