import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Frontpage from '@/views/Frontpage'
import Register from '@/views/Register'
import RegisterConfirmation from '@/views/RegisterConfirmation'
import Login from '@/views/Login'
import Repositories from '@/views/Repositories'
import Account from '@/views/Account'
import PasswordReset from '@/views/PasswordReset'
import PasswordResetInit from '@/views/PasswordResetInit'
import SSHKeys from '@/views/SSHKeys'

const Privacy = () => import('@/views/Privacy')
const TOS = () => import('@/views/TOS')
const GDPR = () => import('@/views/GDPR')
const Checkout = () => import('@/views/Checkout')
const Setup = () => import('@/views/Setup')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Frontpage',
      component: Frontpage,
      meta: {
        title: 'Simple and Secure Offsite Backups'
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: 'Sign Up'
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: 'Privacy Policy'
      }
    },
    {
      path: '/tos',
      name: 'TOS',
      component: TOS,
      meta: {
        title: 'Terms of Service'
      }
    },
    {
      path: '/gdpr',
      name: 'GDPR',
      component: GDPR
    },
    {
      path: '/register-confirm',
      name: 'RegisterConfirmation',
      component: RegisterConfirmation,
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgot-password',
      name: 'PasswordResetInit',
      component: PasswordResetInit,
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/reset-password',
      name: 'PasswordReset',
      component: PasswordReset,
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/repositories',
      name: 'Repositories',
      component: Repositories,
    },
    {
      path: '/setup',
      name: 'Setup',
      component: Setup
    },
    {
      path: '/ssh',
      name: 'SSHKeys',
      component: SSHKeys,
      meta: {
        title: 'SSH Keys'
      }
    },
    {
      path: '/account',
      name: 'Account',
      component: Account
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: Checkout
    },
    { path: '/checkout/:invoiceId',
      props: true,
      name: 'CheckoutSingle',
      component: Checkout
    }
  ]
})

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/', '/register', '/register-confirm', '/gdpr',
                       '/privacy', '/tos', '/forgot-password', '/reset-password'];
  let accountPromise = store.dispatch('auth/accountDetails')
  if (publicPages.includes(to.path)) {
    next()
  } else {
    accountPromise.then( () => {
      const loggedIn = store.getters['auth/isAuthenticated']
      if (!loggedIn) {
        return next({ name: 'Login', query: { redirect: to.path } })
      } else if (to.path === '/login' && loggedIn) {
        next('/repositories')
      } else {
        store.dispatch('auth/refreshSession')
        next()
      }
    })
  }
})

// Set page title. Use route name if not available.
router.afterEach((to) => {
  let pageTitle = to.meta && to.meta.title ? to.meta.title : to.name;
  window.document.title = `BorgBase - ${pageTitle}`
})

export default router
