<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center mt-5">
        <h1>Login</h1>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-alert :value="true" type="success" v-show="fromActivationEmail">
          Account was activated. You can sign in now.
        </v-alert>
        <v-alert :value="true" type="info" v-show="isRedirecting">
          Please log in to view this page.
        </v-alert>
        <v-alert :value="!!errors" v-show="errors" type="error">
          {{ errors }}
        </v-alert>
        <v-alert type="info" v-show="showOTP & !errors"
          >Please provide your OTP token.</v-alert
        >
        <v-card rounded class="pa-5">
        <form>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="email"
                autofocus
                label="Email"
                v-model="email"
                id="email"
                type="email"
                prepend-icon="fa-user"
                color="primary"
                class="mt-4"
                :disabled="showOTP"
                :error-messages="emailErrors"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="password"
                label="Password"
                id="password"
                type="password"
                v-model="password"
                prepend-icon="fa-lock"
                color="primary"
                :error-messages="passwordErrors"
                :disabled="showOTP"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showOTP">
            <v-col cols="6" >
              <v-text-field
                name="otp"
                placeholder="2FA Token"
                id="otp"
                type="text"
                v-model="otp"
                ref="otpInput"
                prepend-icon="fa-key"
                color="primary"
                autocomplete="off"
                :error-messages="otpErrors"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" v-show="showOTP">
              <v-checkbox
                id="rememberDevice"
                v-model="rememberDevice"
                label="Remember this device for 30 days."
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                Forgot your password? Reset it
                <router-link to="/forgot-password">here</router-link>.
              </p>
            </v-col>
            <v-col class="text-center" cols="12">
              <v-btn
                class="primary"
                dark
                text
                :loading="loading"
                type="submit"
                @click.prevent="login"
                >
                <v-icon left small>fa-sign-in-alt</v-icon>
                Sign In</v-btn
              >
            </v-col>
          </v-row>
        </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
  email
} from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      otp: "",
      rememberDevice: false,
      showOTP: false,
      loading: false,
    };
  },
  computed: {
    fromActivationEmail() {
      return this.$route.query.ref === "activate";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 8 characters long");
      !this.$v.email.required && errors.push("Password is required");
      return errors;
    },
    otpErrors() {
      const errors = [];
      if (!this.$v.otp.$dirty) return errors;
      !this.$v.otp.minLength && errors.push("OTP must be 6 digits.");
      !this.$v.otp.maxLength && errors.push("OTP must be 6 digits.");
      !this.$v.otp.numeric && errors.push("OTP must be 6 digits.");
      return errors;
    },
    errors() {
      return this.$store.state.auth.error;
    },
    isRedirecting() {
      return this.$route.query.redirect != undefined;
    }
  },
  methods: {
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let vars = {
        email: this.email,
        password: this.password,
        rememberDevice: this.rememberDevice,
        rememberDeviceToken: localStorage.getItem('rememberDeviceToken'),
      };

      if (/^\d{6}$/.test(this.otp)) {
        vars["otp"] = this.otp;
      }

      try {
        this.loading = true;
        await this.$store.dispatch("auth/login", vars)
        if (this.isRedirecting) {
          this.$router.push({ path: this.$route.query.redirect });
        } else {
          this.$router.push({ name: "Repositories" });
        }
      } catch(e) {
        if (e.message.endsWith("Please provide OTP.")) {
          this.showOTP = true;
          this.$nextTick(() => this.$refs.otpInput.focus());
        } else {
          this.$store.commit("auth/setError", e.message);
        }
      } finally {
        this.loading = false;
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    },
    otp: {
      minLength: minLength(6),
      maxLength: maxLength(6),
      numeric
    }
  }
};
</script>

<style scoped></style>
