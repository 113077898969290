<template>
  <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" class="text-center mt-5">
          <h1>Set New Password</h1>
        </v-col>
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card rounded class="pa-5">
            <v-row>
              <v-col>
                <v-alert :value="true" type="success" v-show="!!success"
                >Password was reset. You can now log in with your new password.
                </v-alert>
                <v-alert :value="errors" type="error" v-show="!!errors">
                  {{ errors }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12">
                <v-text-field
                        :error-messages="passwordErrors"
                        v-model="password"
                        label="Password"
                        type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                        label="Confirm Password"
                        type="password"
                        :error-messages="confirmPasswordErrors"
                        v-model="passwordConfirm"
                ></v-text-field>
              </v-col>
              <v-col class="text-center mt-3">
                <v-btn
                        color="primary"
                        dark
                        type="submit"
                        @click.prevent="submit"
                        :loading="loading"
                >
                  Set New Password</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import request from '@/plugins/graphql'
import PASSWORD_RESET from "@/graphql/users/AuthPasswordReset.graphql";
import validationsMixin from "@/mixins/validations";

export default {
  name: "PasswordReset",
  mixins: [validationsMixin],
  data() {
    return {
      password: "",
      passwordConfirm: "",
      loading: false,
      success: false,
      errors: ""
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        await request(PASSWORD_RESET, { password: this.password, token: this.token })
        this.success = true;
        this.errors = "";
      } catch(err) {
        this.errors = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    token() {
      return this.$route.query.token;
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    passwordConfirm: {
      required,
      sameAs: sameAs("password")
    }
  }
};
</script>

<style scoped></style>
