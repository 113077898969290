<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Your Backup Repositories</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat class="elevation-2 mb-1">
          <v-toolbar-title>
            <v-tooltip right max-width="250">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  Current Usage:
                  <small class="font-weight-bold">
                    <v-badge dot :color="diskUsageColor">{{ diskUsage | prettyBytes }}</v-badge>
                  </small>
                </span>
              </template>
              <span>
                Your plan includes {{ user.activePlan.includedSize | prettyBytes }} with a flexible
                quota of up to {{ user.activePlan.maxSize | prettyBytes }}.
              </span>
            </v-tooltip>
            <v-tooltip right max-width="250">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  dark
                  icon
                  small
                  class="ml-3"
                  color="grey"
                  v-on="on"
                  :to="{ name: 'Account', query: { tab: 0 } }"
                >
                  <v-icon small>fa-pen</v-icon>
                </v-btn>
              </template>
              <span>
                View details or make changes to your plan.
              </span>
            </v-tooltip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-icon="fa-search"
            label="Filter repositories"
            single-line
            hide-details
            color="grey darken-3"
            clearable
            clear-icon="fa-times"
            class="shrink d-none d-md-inline-flex"
          ></v-text-field>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="primary" @click="refreshRepos" v-on="on">
                <v-icon>fa-sync-alt</v-icon>
              </v-btn>
            </template>
            <span>Refresh repo details</span>
          </v-tooltip>
          <RepoAddButton />
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="repos"
          item-key="id"
          class="elevation-2"
          :expanded.sync="expanded"
          :search="search"
          sort-by="name"
          single-expand
          :footer-props="{
            'items-per-page-options': [20, 30, 50, 80, 100, -1]
          }"
          :items-per-page="30"
          data-cy="table-repos"
          id="repo-table"
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" class="ma-0" @click="copyPath(item.repoPath, item.name)">
                  <v-icon :color="item.isNew ? 'primary' : 'grey'" small>fa-copy</v-icon>
                </v-btn>
              </template>
              <span
                >Click to copy repo path:
                <pre>{{ item.repoPath }}</pre></span
              >
            </v-tooltip>
            {{ item.name }}
          </template>
          <template v-slot:item.currentUsage="{ item }">
            {{ item.currentUsage | prettyBytes }}
            <v-tooltip top max-width="200px" v-if="item.quotaEnabled">
              <template v-slot:activator="{ on }">
                <v-progress-circular
                  :value="(item.currentUsage / item.quota) * 100"
                  :width="5"
                  :size="25"
                  color="primary"
                  v-on="on"
                ></v-progress-circular>
              </template>
              <span
                >Assigned Quota: {{ item.quota | prettyBytes }}. If you exceed your quota during a
                backup, leftover data will be removed automatically during the next repo-write
                operation.</span
              >
            </v-tooltip>
          </template>
          <template v-slot:item.keys="{ item }">
            <KeyChipsWithMoreIcon
              :fullAccessKeys="item.fullAccessKeys"
              :appendOnlyKeys="item.appendOnlyKeys"
            ></KeyChipsWithMoreIcon>
            <span class="text-caption" v-if="item.format === 'restic'">Not used for Restic</span>
          </template>

          <template v-slot:item.region="{ item }">
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on }">
                <span v-on="on"
                  ><b>{{ item.region.toUpperCase() }}</b></span
                >
              </template>
              <span
                >Located on <code>{{ item.server.hostname }}</code> in
                {{ item.server.location }}</span
              >
            </v-tooltip>
          </template>
          <template v-slot:item.format="{ item }">
            <v-tooltip top v-if="item.format.includes('borg')">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small left>$borgbackup</v-icon>
              </template>
              Uses Borg Backup
            </v-tooltip>
            <v-tooltip top v-if="item.format.includes('restic')">
              <template v-slot:activator="{ on }">
                <v-icon small left v-on="on" color="blue">fa-umbrella</v-icon>
              </template>
              Uses Restic
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small left>
                  {{ item.encryption != "none" ? "fa-lock" : "fa-lock-open" }}
                </v-icon>
              </template>
              <span
                >Encryption:
                <pre>{{ item.encryption }}</pre></span
              >
            </v-tooltip>
            <v-tooltip top v-if="item.format.includes('restic') && item.appendOnly">
              <template v-slot:activator="{ on }">
                <v-icon small left v-on="on">fa-ban</v-icon>
              </template>
              This repository is in append-only mode. No data can be removed.
            </v-tooltip>
            <v-tooltip top v-if="item.format.includes('borg')">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small left>fa-fingerprint</v-icon>
              </template>
              <span
                >SSH Host Key Fingerprints:<br />
                RSA: <code>{{ item.server.fingerprintRsa }}</code
                ><br />
                ED25519: <code>{{ item.server.fingerprintEd25519 }}</code
                ><br />
                ECDSA: <code>{{ item.server.fingerprintEcdsa }}</code>
              </span>
            </v-tooltip>
            <v-tooltip top v-if="item.sftpEnabled">
              <template v-slot:activator="{ on }">
                <v-icon small left color="error" v-on="on">fa-folder-open</v-icon>
              </template>
              SFTP is enabled for this repository.
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small left v-show="item.compactionEnabled"
                  >fa-compress-arrows-alt</v-icon
                >
              </template>
              Compactions are scheduled to run every {{ item.compactionInterval }}
              {{ item.compactionIntervalUnit }} at {{ item.compactionHour }}:00 local time.
            </v-tooltip>
          </template>
          <template v-slot:item.lastModified="{ item }">
            <v-tooltip top v-if="item.lastModified">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.lastModified | formatDateDistance }}</span>
              </template>
              <span>{{ item.lastModified | formatDateTime }}</span>
            </v-tooltip>
            <span v-else>Never</span>
          </template>
          <template v-slot:item.alertDays="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-badge dot color="red darken-2" :value="repoHasOutdatedModification(item)">
                    {{ item.alertDays > 0 ? item.alertDays + " days" : "Disabled" }}</v-badge
                  >
                </span>
              </template>
              <span v-if="repoHasOutdatedModification(item)">
                This repo wasn't modified within the set monitoring time frame.
              </span>
              <span v-else>
                You will receive an email alert if no new backups are detected.
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="ml-0 mr-0 pl-0"
                  v-on="on"
                  @click="openRepoEditDialog(item.id)"
                >
                  <v-icon color="grey" small>fa-pen</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="ml-0 mr-0">
                  <v-icon color="grey" small @click="expandRepoTable(item, 'RepoTableExpandLog')"
                    >fa-list</v-icon
                  >
                </v-btn>
              </template>
              <span>Event Log</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="ml-0 mr-0">
                  <v-icon color="grey" small @click="expandRepoTable(item, 'RepoTableExpandUsage')"
                    >fa-chart-bar</v-icon
                  >
                </v-btn>
              </template>
              <span>Usage</span>
            </v-tooltip>
            <v-menu offset-y>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon color="grey" small>fa-caret-square-down</v-icon>
                    </v-btn>
                  </template>
                  <span>More...</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item @click="compactRepo(item.id)" v-show="item.format.includes('borg')">
                  <v-list-item-icon>
                    <v-icon small color="grey">fa-compress-arrows-alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Compact repo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$refs.repoDeleteDialog.openDeleteDialog(item.id, item.name)">
                  <v-list-item-icon>
                    <v-icon small color="grey">fa-trash</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete repo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <component :repoId="item.id" :is="expandComponent">hello</component>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Global elements, like dialogs and snackbar -->
    <RepoDialog ref="repoEditDialog" v-model="repoEditDialogOpen" @repoDialogClosedEvent="repoEditDialogOpen = false"></RepoDialog>
    <RepoDeleteDialog ref="repoDeleteDialog"></RepoDeleteDialog>
    <v-snackbar v-model="feedbackSnackBarOpen" timeout="5000" top>
      {{ feedbackSnackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="feedbackSnackBarOpen = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import RepoTableExpandLog from "@/components/RepoTableExpandLog";
import RepoDialog from "@/components/RepoDialog";
import RepoAddButton from "@/components/RepoAddButton";
import RepoDeleteDialog from "@/components/RepoDeleteDialog";
import KeyChipsWithMoreIcon from "@/partials/KeyChipsWithMoreIcon";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
const RepoTableExpandUsage = () => import("@/components/RepoTableExpandUsage");

export default {
  name: "Repositories",
  components: {
    RepoDialog,
    RepoAddButton,
    RepoDeleteDialog,
    RepoTableExpandLog,
    RepoTableExpandUsage,
    KeyChipsWithMoreIcon
  },
  computed: {
    repos() {
      return this.$store.getters["repos/repoList"];
    },
    diskUsage() {
      return this.$store.getters["repos/diskUsage"];
    },
    diskUsageColor() {
      let currentUsage = this.$store.getters["repos/diskUsage"];
      let plan = this.$store.state.auth.user.activePlan;
      if (currentUsage < plan.includedSize) {
        return "green darken-3";
      } else if (currentUsage < plan.maxSize * 0.95) {
        return "orange darken-3";
      }
      return "red darken-3";
    },
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    openRepoEditDialog(repoId) {
      this.repoEditDialogOpen = true;
      this.$refs.repoEditDialog.populate('update', repoId);
    },
    copyPath(url, name) {
      copy(url);
      this.feedbackSnackBarText = `Repo URL for ${name} was copied to the clipboard.`;
      this.feedbackSnackBarOpen = true;
    },
    refreshRepos() {
      this.$store.dispatch("repos/list");
      this.$store.dispatch("ssh/list");
    },
    compactRepo(id) {
      this.$store.dispatch("repos/compact", id);
      this.feedbackSnackBarText =
        "Repo compaction scheduled. This can take several minutes to an hour.";
      this.feedbackSnackBarOpen = true;
    },
    expandRepoTable(item, component) {
      // Expansion already open. Change component or close it.
      if (this.expanded.length > 0) {
        if (this.expandComponent === component) {
          this.expanded = [];
        } else {
          this.expandComponent = component;
        }
        // Nothing open. Set component and open it.
      } else {
        this.expandComponent = component;
        this.expanded = [item];
      }
    },
    repoHasOutdatedModification(repo) {
      if (repo.alertDays > 0 && dayjs(repo.lastModified).add(repo.alertDays, "days") < dayjs()) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      repoEditDialogOpen: false,
      expandComponent: "RepoTableExpand",
      expanded: [],
      search: "",
      feedbackSnackBarOpen: false,
      feedbackSnackBarText: "Lorem",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Usage", value: "currentUsage", sortable: true },
        { text: "Keys", value: "keys", sortable: false },
        { text: "Region", value: "region", sortable: true, align: "center" },
        {
          text: "Details",
          value: "format",
          sortable: false,
          align: "center"
        },
        { text: "Modified", value: "lastModified", sortable: true },
        { text: "Alert", value: "alertDays", sortable: true },
        { text: "Actions", value: "id", sortable: false, align: "center" }
      ]
    };
  }
};
</script>

<style scoped>
#repo-table >>> td:not(:first-child) {
  padding: 0 5px;
}
#repo-table >>> td:last-child {
  white-space: nowrap;
}

#repo-table >>> .alert-outdated {
  font-weight: bold;
  background-color: #dd2c00;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.v-toolbar__title {
  white-space: normal;
  text-overflow: clip;
}

tr.v-data-table__expanded__content td {
  padding: 5px !important;
}
</style>
