<template>
  <v-card flat>
    <v-card-text>
      <v-data-table
        :items="events"
        :headers="headers"
        :loading="loading"
        class="repo-event-table"
        sort-by="datetime"
        :sort-desc="true"
        dense
      >
        <template v-slot:item.datetime="{ item }">
          {{ item.datetime | formatDateTime }}
        </template>
        <template v-slot:item.ip="{ item }">
          {{ item.ip }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import request from '@/plugins/graphql'
import REPO_EVENT_LIST from "@/graphql/repos/RepoEventList.graphql";
export default {
  name: "RepoTableExpandLog",
  props: ["repoId"],
  data() {
    return {
      loading: true,
      events: [],
      headers: [
        { text: "Datetime", value: "datetime", width: "200" },
        { text: "Event", value: "slug", width: "120" },
        { text: "IP", value: "ip", width: "200" },
        { text: "SSH Key", value: "sshKeyName", width: "200" },
        { text: "Details", value: "details" },
      ],
      pagination: { sortBy: "datetime", descending: true, rowsPerPage: 10 }
    };
  },
  async mounted() {
    try {
      const data = await request(REPO_EVENT_LIST, { repoId: this.repoId })
      this.events = data.repoEventList;
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.repo-event-table >>> tr:hover {
  background: none !important;
}
</style>
