<template>
  <div>
    <v-tooltip top max-width="250" :key="item.id" v-for="item in keys.slice(0, 2)">
      <template v-slot:activator="{ on }">
        <v-chip v-on="on" small>
          <v-avatar v-if="item.permission != 'full'">
            <v-icon small v-if="item.permission=='append-only'">fa-ban</v-icon>
            <v-icon small v-if="item.permission=='sftp'">fa-folder-open</v-icon>
          </v-avatar>{{ item.name }}
        </v-chip>
      </template>
      {{ item.name }} ({{ item.permissionLabel }})
    </v-tooltip>
    <v-tooltip top max-width="250" v-if="keys.length > 2">
      <template v-slot:activator="{ on }">
        <span v-on="on">&hellip;</span>
      </template>
      <span v-for="(item, index) in keys.slice(2)" :key="item.id">
        {{ item.name }}
      <span v-if="index < keys.length-3">,</span>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "KeyChipsWithMoreIcon",
  props: ["fullAccessKeys", "appendOnlyKeys"],
  methods: {
    keyIdToName: id => store.getters["ssh/list"].filter(o => o.id == String(id))[0].name
  },
  computed: {
    keys() {
      let keys = [];
      this.fullAccessKeys.forEach(id => {
        keys.push({
          id: id,
          name: this.keyIdToName(id),
          permission: "full",
          permissionLabel: "full access"
        });
      });
      this.appendOnlyKeys.forEach(id => {
        keys.push({
          id: id,
          name: this.keyIdToName(id),
          permission: "append-only",
          permissionLabel: "append-only access"
        });
      });
      return keys;
    }
  }
};
</script>

<style scoped>
.v-chip {
  margin: 2px;
  padding: 0 5px;
}
.v-chip >>> .v-chip__content {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  display: block;
}
.last-key-chip {
  white-space: nowrap;
}
</style>
