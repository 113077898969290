<template>
  <v-row>
    <v-col cols="12" md="8">
      <v-row align="center">
        <v-col cols="6" sm="3">
          <v-subheader v-text="'Alert Interval:'"></v-subheader>
        </v-col>
        <v-col cols="6" sm="3">
          <v-select
            v-model="alerts.interval"
            :items="intervalOptions"
            :menu-props="{ maxHeight: '400' }"
            label="Alert Interval"
            hint="How long to wait between alerts."
            persistent-hint
          ></v-select>
        </v-col>
        <v-col cols="6" sm="3">
          <v-subheader v-text="'Alert Duration:'"></v-subheader>
        </v-col>
        <v-col cols="6" sm="3">
          <v-select
            v-model="alerts.duration"
            :items="durationOptions"
            :menu-props="{ maxHeight: '400' }"
            label="Total Alert Duration"
            hint="For how long do you want to receive alerts about inactive repos?"
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <v-switch
                  label="Email"
                  class="flex-grow-0"
                  inset
                  v-model="alerts.email.enabled"
                  @click.stop
                ></v-switch>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-checkbox
                      :label="
                        `Send to main account address (${this.$store.state.auth.user.username})`
                      "
                      v-model="alerts.email.usemain"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-show="!alerts.email.usemain">
                  <v-col cols="4">
                    <v-subheader>Alert Address</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="Email to receive alerts"
                      v-model="alerts.email.recipient"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <v-switch
                  label="Pushover"
                  class="flex-grow-0"
                  inset
                  @click.stop
                  v-model="alerts.pushover.enabled"
                >
                </v-switch>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="ml-2 mr-2">
                  <v-col md="6">
                    <v-text-field
                      label="User Key"
                      v-model="alerts.pushover.userkey"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Application Key"
                      v-model="alerts.pushover.appkey"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <v-switch
                  label="GET Webhook"
                  class="flex-grow-0"
                  inset
                  @click.stop
                  v-model="alerts.webhook.enabled"
                >
                </v-switch>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="ml-2 mr-2">
                  <v-col cols="4">
                    <v-subheader>URL for GET Request</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="URL"
                      v-model="alerts.webhook.recipient"
                      persistent-hint
                      placeholder="https://webhook.site/xxx-xxx"
                      hint="URL parameters added: repo_id, repo_name, repo_alert_days, repo_last_modified"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0">
                <v-switch
                  label="POST Webhook"
                  class="flex-grow-0"
                  inset
                  @click.stop
                  v-model="alerts.webhookpost.enabled"
                >
                </v-switch>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="ml-2 mr-2">
                  <v-col cols="4">
                    <v-subheader>URL for POST Request</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      label="URL"
                      v-model="alerts.webhookpost.recipient"
                      placeholder="https://hooks.slack.com/services/xxx/xxx/xxx"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-subheader>Payload for POST Request</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-textarea
                      name="input-7-1"
                      filled
                      hint="Sent as JSON (application/json). Supported placeholders: *repo_id*, *repo_name*, *repo_last_modified*, *repo_last_modified_days*"
                      auto-grow
                      persistent-hint
                      placeholder='{ "text": "Backup failed for *repo_name*" }'
                      v-model="alerts.webhookpost.payload"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row justify="start" align="center" class="my-5 mx-0">
        <v-btn
          class="white--text mr-3"
          color="primary"
          depressed
          @click="submit"
          :loading="loading"
          >Save and Test</v-btn
        >
        <span v-show="!!message">{{ message }}</span>
      </v-row>
    </v-col>
    <v-col cols="12" md="4">
      <p>
        How you get alerted when backups fail for too long. You can set the
        alert delay per-repo. The settings here specify <em>how</em> you get
        alerted and apply to all repos.
      </p>
      <p>
        The <em>Alert Delay</em> is set per-repo. If there are no new backups to
        this repo for longer than that, you will start receiving notifications
        as specified here. <em>Alert Interval</em> determines how often you get
        an alert. E.g. every few hours. <em>Alert Duration</em> is for how long
        you want alerts. After the number of days has passed, you won't get any
        more alerts. If you ever do new backups to the repo, the cycle start
        again.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccountAlerts",
  data() {
    const alertDefault = {
      email: {
        enabled: true,
        recipient: "",
        usemain: true
      },
      pushover: {
        enabled: false,
        appkey: "",
        userkey: ""
      },
      webhook: {
        enabled: false,
        recipient: ""
      },
      webhookpost: {
        enabled: false,
        recipient: "",
        payload: ""
      },
      interval: 12,
      duration: 5
    };
    return {
      alerts: { ...alertDefault, ...this.$store.state.auth.user.alerts },
      message: "",
      loading: false,
      intervalOptions: [
        { text: "12 hours", value: 12 },
        { text: "24 hours", value: 24 },
        { text: "48 hours", value: 48 }
      ],
      durationOptions: [
        { text: "3 days", value: 3 },
        { text: "5 days", value: 5 },
        { text: "10 days", value: 10 },
        { text: "14 days", value: 14 },
        { text: "21 days", value: 21 },
        { text: "30 days", value: 30 }
      ]
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("auth/alertUpdate", this.alerts)
        .then(() => {
          this.loading = false;
          this.message = "Alert settings were saved.";
        })
        .catch(err => {
          this.loading = false;
          this.message = err.message;
        });
    }
  }
};
</script>

<style scoped>
.v-textarea >>> textarea {
  font-family: monospace;
  font-size: 14px;
}
</style>
