import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueCompositionAPI from '@vue/composition-api'

import { prettyBytes } from '@/filters/pretty-bytes.filter'
import { formatDateDistance } from '@/filters/date-distance.filter'
import { formatDateTime, formatDate } from '@/filters/date-time.filter'
import { buildInvoiceURL } from '@/filters/invoice-url.filter'

Sentry.init({ dsn: process.env.VUE_APP_SENTRY_DSN });

Vue.use(Vuelidate)
Vue.use(VueCompositionAPI)
Vue.filter('prettyBytes', prettyBytes)
Vue.filter('formatDateDistance', formatDateDistance)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatDate', formatDate)
Vue.filter('buildInvoiceURL', buildInvoiceURL)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    setTimeout(()=>{
      this.$store.dispatch('auth/saveReferer')
    }, 3000)
    document.dispatchEvent(new Event("x-app-rendered"))
  }
}).$mount('#app')
