import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export function formatDateDistance (date) {
  if (date) {
    return dayjs(date).fromNow()
  } else {
    return 'Never'
  }
}
