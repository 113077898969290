<template>
  <v-container>
    <v-row>
      <v-col md="12">
        <v-tabs
          class="elevation-2"
          v-model="currentTab"
          show-arrows
          slider-color="primary"
        >
          <v-tab :key="0">Subscription</v-tab>
          <v-tab :key="1">Alerts</v-tab>
          <v-tab :key="2">API</v-tab>
          <v-tab :key="3">Password/2FA</v-tab>
          <v-tab :key="4">Billing</v-tab>
          <v-tab :key="5">Profile</v-tab>
          <v-tab-item :key="0">
            <v-container>
              <v-row>
                <v-col cols="12" md="7">
                  <h2>Your Current Plan</h2>
                  <SubscriptionCurrent></SubscriptionCurrent>
                </v-col>
                <v-col cols="12" md="5">
                  <h2>Add or Extend Plan</h2>
                  <SubscriptionChange></SubscriptionChange>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
                    <v-tab-item :key="1">
            <v-container>
              <h2>Manage Alerts</h2>
              <AccountAlerts></AccountAlerts>
            </v-container>
          </v-tab-item>
                    <v-tab-item :key="2">
            <JWTTable></JWTTable>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-container>
              <v-row>
                <v-col cols="12" sm="5">
                  <h2>Change password</h2>
                  <AccountPassword></AccountPassword>
                </v-col>
                <v-col cols="12" sm="7">
                  <h2>2-Factor-Authentication</h2>
                  <Account2FA></Account2FA>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>



          <v-tab-item :key="4">
            <v-container>
              <v-row>
                <v-col md="6" cols="12">
                  <h2>Invoice History</h2>
                  <BillingInvoiceHistory></BillingInvoiceHistory>
                </v-col>
                <v-col md="6" cols="12">
                  <h2>
                    Usage History
                    <v-tooltip top max-width="250">
                      <template v-slot:activator="{ on }">
                        <small v-show="accruedOverage != 0">
                          <span v-on="on" class="text--secondary"
                            >Accrued Excess Data Cost: ${{
                              accruedOverage.toFixed(2)
                            }}</span
                          >
                        </small>
                      </template>
                      <span
                        >The sum of all past daily overage charges minus
                        whatever was paid with past invoices. The balance will
                        be invoiced during your next renewal.</span
                      >
                    </v-tooltip>
                  </h2>
                  <BillingUsageHistory></BillingUsageHistory>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="5">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mt-6">
                  <h2>Billing Details</h2>
                  <BillingDetailsForm show-submit-button></BillingDetailsForm>
                </v-col>
                <v-col cols="12" md="6">
                  <v-container>
                    <AccountChangeEmail></AccountChangeEmail>
                    <AccountChangeReminders></AccountChangeReminders>
                    <AccountRemove></AccountRemove>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AccountPassword from "@/components/AccountPassword";
import AccountAlerts from "@/components/AccountAlerts";
import Account2FA from "@/components/Account2FA";
import SubscriptionCurrent from "@/components/SubscriptionCurrent";
import SubscriptionChange from "@/components/SubscriptionChange";
import BillingDetailsForm from "@/components/BillingDetailsForm";
import BillingInvoiceHistory from "@/components/BillingInvoiceHistory";
import BillingUsageHistory from "@/components/BillingUsageHistory";
import JWTTable from "../components/JWTTable";
import AccountRemove from "@/components/AccountRemove";
import AccountChangeEmail from "@/components/AccountChangeEmail";
import AccountChangeReminders from "@/components/AccountChangeReminders";

export default {
  name: "Account",
  components: {
    JWTTable,
    AccountPassword,
    AccountAlerts,
    Account2FA,
    AccountRemove,
    AccountChangeEmail,
    AccountChangeReminders,
    SubscriptionCurrent,
    SubscriptionChange,
    BillingDetailsForm,
    BillingInvoiceHistory,
    BillingUsageHistory
  },
  data() {
    return {
      currentTab: 0
    };
  },
  computed: {
    package() {
      return this.$store.state.repos.plan;
    },
    ...mapGetters({
      quotaAvailable: "repos/quotaAvailable",
      accruedOverage: "auth/getAccruedOverage"
    })
  },
  watch: {
    currentTab(newTab) {
      if (newTab !== parseInt(this.$route.query.tab)) {
        this.$router.push({ query: { ...this.$route.query, tab: newTab } });
      }
    }
  },
  beforeMount() {
    if (this.$route.query.tab) {
      this.currentTab = parseInt(this.$route.query.tab);
    }
  }
};
</script>

<style scoped></style>
