<template>
  <div>
    <v-text-field
      color="primary"
      label="Current Password"
      type="password"
      class="mt-4"
      v-model="currentPassword"
      data-cy="input-current-password"
      :error-messages="currentPasswordErrors"
    ></v-text-field>
    <v-text-field
      color="primary"
      counter
      hint="At least 8 characters"
      label="New Password"
      type="password"
      v-model="password"
      data-cy="input-new-password"
      :error-messages="passwordErrors"
    ></v-text-field>
    <v-text-field
      color="primary"
      label="New Password (Confirm)"
      type="password"
      v-model="passwordConfirm"
      data-cy="input-new-password-confirm"
      :error-messages="confirmPasswordErrors"
    ></v-text-field>
    <v-btn class="white--text" color="primary" depressed @click="submit" data-cy="submit"
      >Save</v-btn
    >
    <span class="ml-3" data-cy="alert-password">{{ message }}</span>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";

export default {
  name: "AccountPassword",
  mixins: [validationsMixin],
  data() {
    return {
      currentPassword: "",
      password: "",
      passwordConfirm: "",
      message: ""
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const vars = {
        newPassword: this.password,
        oldPassword: this.currentPassword
      };
      this.$store
        .dispatch("auth/passwordChange", vars)
        .then(() => {
          this.message = "Password successfully changed";
          this.currentPassword = "";
          this.password = "";
          this.passwordConfirm = "";
          this.$v.$reset();
        })
        .catch(e => {
          this.message = e.message;
        });
    }
  },
  validations: {
    currentPassword: { required },
    password: { required, minLength: minLength(8) },
    passwordConfirm: { required, sameAs: sameAs("password" + "") }
  }
};
</script>

<style scoped></style>
