<template>
  <v-tooltip top max-width="250" :close-delay="closeDelay">
    <template v-slot:activator="{ on }">
    <v-icon
      v-on="on"
      color="grey"
      small
      dark
    >fa-question-circle</v-icon>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'HelpIconWithToolTip',
    props: {
      closeDelay: {
        type: Number,
        default: 0
      },
    }
  }
</script>

<style scoped>

</style>
