<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center mt-5">
        <h1>Reset Password</h1>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card rounded class="pa-5">
          <v-row>
            <v-col>
              <v-alert type="success" v-show="success" data-cy="alert-success"
              >Password reset request received. If an account with this email
                exists, you will receive a reset link.
              </v-alert>
              <v-alert :value="errors" type="error" v-show="!!errors">
                {{ errors }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p>
                Enter your account email to reset your password. If an account
                with that email exists, we will send you a reset link.
              </p>
            </v-col>
            <v-col cols="12">
              <v-text-field
                autofocus
                name="email"
                label="Email"
                data-cy="input-email"
                v-model="email"
                id="email"
                type="email"
                prepend-icon="fa-user"
                color="primary"
                class="mt-4"
                :error-messages="emailErrors"
                required
              ></v-text-field>
            </v-col>
            <v-col class="text-center mt-3">
              <v-btn
                color="primary"
                data-cy="submit"
                dark
                type="submit"
                @click.prevent="submit"
                :loading="loading"
              >
                Send Password Reset Link</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";
import request from '@/plugins/graphql'
import PASSWORD_RESET_INIT from "@/graphql/users/AuthPasswordResetInit.graphql";

export default {
  name: "PasswordResetInit",
  mixins: [validationsMixin],
  data() {
    return {
      email: "",
      errors: "",
      success: false,
      loading: false
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      try {
        await request(PASSWORD_RESET_INIT, { username: this.email })
        this.success = true;
        this.errors = "";
      } catch(err) {
        this.errors = err.message;
      } finally {
        this.loading = false;
        }
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>

<style scoped></style>
