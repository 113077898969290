<template>
  <v-col class="xs" lg="3" md="4" sm="6">
    <v-card class="pricing-card" height="100%">
      <v-card-title primary-title class="secondary white--text">
        <h1 class="headline">
          <span class="plan-name"
            ><b>{{ planName }}</b></span
          >
          <span class="plan-price">{{ planPrice }}</span>
        </h1>
        <small class="subprice-large text-right">{{ subtitle }}</small>
      </v-card-title>
      <v-card-text>
        <p class="mt-4 mb-0 pa-2">{{ planDesc }}</p>
        <v-list two-line>
          <v-list-item three-line>
            <v-list-item-avatar>
              <v-icon class="primary white--text">fa-hdd</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><h3>{{ includedSize }} {{ sizeUnit }} included</h3>
                </v-list-item-title
              >
                            <v-list-item-subtitle>
                Additional usage
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                ${{ priceExtraSpace }}/{{ sizeUnit }}/month <HelpIconWithToolTip>
                  This plan comes with {{ includedSize }} {{ sizeUnit }} of backup space, but you
                  can use up to {{ maxSize }} {{ sizeUnit }} (flexible quota), which is charged at
                  ${{ priceExtraSpace }}/{{ sizeUnit }} per month when renewing.
                </HelpIconWithToolTip>
              </v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="primary white--text">fa-server</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ includedRepos }}</v-list-item-title>
              <v-list-item-subtitle>Keep backups isolated</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item three-line>
            <v-list-item-avatar>
              <v-icon class="primary white--text">fa-globe</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>EU and US Regions</v-list-item-title>
              <v-list-item-subtitle>Mix multiple regions</v-list-item-subtitle>
              <v-list-item-subtitle>
                as needed
                <HelpIconWithToolTip>
                  You can choose a different location for each new backup repository, allowing you
                  to distribute your backup across different geographies as needed.
                </HelpIconWithToolTip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="primary white--text">fa-question-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Expert Support</v-list-item-title>
              <v-list-item-subtitle>
                Via e-mail
                <HelpIconWithToolTip>
                  No tickets, no canned responses. Get competent support by actual open source
                  maintainers and sysadmins using Borg with real customers. Most questions are
                  answered within hours during working days.
                </HelpIconWithToolTip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row align="center" justify="center">
          <v-btn class="primary mb-5" dark text to="/register">Sign Up </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "PricingCards",
  components: {
    HelpIconWithToolTip
  },
  props: [
    "planName",
    "planPrice",
    "subtitle",
    "planDesc",
    "sizeUnit",
    "includedSize",
    "maxSize",
    "includedRepos",
    "priceExtraSpace"
  ]
};
</script>

<style scoped>
.pricing-card >>> .plan-price {
  float: right;
}
.pricing-card >>> h1,
.pricing-card >>> .subprice-large {
  width: 100%;
}
small.subprice-large {
  font-size: 12px;
}
</style>
