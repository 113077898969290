<template>
  <v-row>
    <v-col>
      <h2>Remove Account</h2>
      <p>
        This will disable your account and clear most personal details from our
        system. Data required for billing- and tax purposes will be kept, as
        long as required by law.
      </p>
      <p>
        Before proceeding, please remove all your repositories.
      </p>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on }">
          <v-btn class="white--text" color="error" depressed v-on="on"
            >Remove Account</v-btn
          >
        </template>

        <v-card>
          <v-card-title class="headline error lighten-1" primary-title>
            Remove Account
          </v-card-title>

          <v-card-text>
            <v-alert :value="!!message" type="error" class="mt-5" dense text>
              {{ message }}
            </v-alert>
            <p class="mt-5">
              We are sorry to see you leave. If there is anything we can
              improve, please drop us an
              <a href="mailto:support@borgbase.com">email</a>.
            </p>
            <p>
              To confirm, please enter your password below and remove all
              repositories from your account.
            </p>
            <p>
              <v-text-field
                label="Password"
                type="password"
                required
                v-model="password"
              ></v-text-field>
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="error" @click="submit" depressed>
              Remove Account
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccountRemove",
  data() {
    return {
      message: "",
      dialog: false,
      password: ""
    };
  },
  methods: {
    submit() {
      const vars = {
        password: this.password
      };
      this.$store
        .dispatch("auth/userRemove", vars)
        .then(() => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/");
        })
        .catch(e => {
          this.message = e.message;
        });
    }
  }
};
</script>

<style scoped></style>
