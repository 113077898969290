export default {
  computed: {
    privacyErrors () {
      const errors = []
      if (!this.$v.privacyAccepted.$dirty) return errors
      !this.privacyAccepted && errors.push('Please accept the privacy policy and terms of service.')
      return errors
    },
    firstNameErrors () {
      const errors = []
      if (!this.$v.firstName.$dirty) return errors
      !this.$v.firstName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.firstName.maxLength && errors.push('Name must be at most 128 characters long')
      !this.$v.firstName.required && errors.push('Name is required.')
      return errors
    },
    lastNameErrors () {
      const errors = []
      if (!this.$v.lastName.$dirty) return errors
      !this.$v.lastName.minLength && errors.push('Name must be at least 4 characters long')
      !this.$v.lastName.maxLength && errors.push('Name must be at most 128 characters long')
      !this.$v.lastName.required && errors.push('Name is required.')
      return errors
    },
    companyNameErrors () {
      const errors = []
      if (!this.isCompany) return errors
      if (!this.$v.companyName.$dirty) return errors
      !this.$v.companyName.required && errors.push('Enter a company name.')
      !this.$v.companyName.minLength && errors.push('Company name is too short.')
      return errors
    },
    companyUidErrors () {
      const errors = []
      if (!this.isCompany) return errors
      if (!this.$v.companyUid.$dirty) return errors
      !this.$v.companyUid.minLength && errors.push('UID format is invalid.')
      return errors
    },
    streetErrors () {
      const errors = []
      if (!this.$v.street.$dirty) return errors
      !this.$v.street.required && errors.push('This field is required')
      !this.$v.street.minLength && errors.push('Too short')
      return errors
    },
    zipErrors () {
      const errors = []
      if (!this.$v.zip.$dirty) return errors
      !this.$v.zip.minLength && errors.push('Zip too short.')
      !this.$v.zip.maxLength && errors.push('Zip too long.')
      return errors
    },
    cityErrors () {
      const errors = []
      if (!this.$v.city.$dirty) return errors
      !this.$v.city.required && errors.push('City is required')
      !this.$v.city.minLength && errors.push('City name too short.')
      return errors
    },
    countryErrors () {
      const errors = []
      if (!this.$v.country.$dirty) return errors
      !this.$v.country.required && errors.push('Select a country.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    emailConfirmErrors () {
      const errors = []
      if (! this.changeEmail) return errors
      if (!this.$v.emailConfirm.$dirty) return errors
      !this.$v.emailConfirm.sameAs && errors.push('Emails must match.')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('Password must be at least 8 characters long')
      !this.$v.password.required && errors.push('Password is required')
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.passwordConfirm.$dirty) return errors
      !this.$v.passwordConfirm.sameAs && errors.push('Passwords must match')
      !this.$v.passwordConfirm.required && errors.push('Confirm your password')
      return errors
    },
    currentPasswordErrors () {
      const errors = []
      if (!this.$v.currentPassword.$dirty) return errors
      !this.$v.currentPassword.required && errors.push('Item is required')
      return errors
    },
    repoNameErrors() {
      const errors = []
      if (!this.$v.repo.name.$dirty) return errors
      !this.$v.repo.name.minLength && errors.push('Name must be at least 2 characters long')
      !this.$v.repo.name.maxLength && errors.push('Name must be at most 64 characters long')
      !this.$v.repo.name.required && errors.push('Name is required.')
      return errors
    }
  }
}
