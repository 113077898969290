import request from '@/plugins/graphql'
import JWT_ADD from '@/graphql/users/JWTAdd.graphql'
import JWT_DELETE from '@/graphql/users/JWTDelete.graphql'

const state = {
  list: [],
  keyString: '',
  errors: '',
  permissionDesc: {
    RO: {
      desc: "This key can only do GraphQL queries and no mutations. Thus it can not change any data.",
      name: "Read Only"
    },
    CO: {
      desc: "This key can only create new repos and SSH keys. It can't read, edit or delete anything else. Allowed GraphQL queries are: repoAdd, sshAdd, repoList, sshList.",
      name: "Create Only"
    },
    FA: {
      desc: "This key has all permissions and can do the same as a fully-authenticated user. Use with care!",
      name: "Full Access"
    },
  }
}

const getters = {
  list: state => state.list.sort( (a, b) => a.name.localeCompare(b.name) )
}

const mutations = {
  setList (state, tokens) {
    state.list = tokens
  },
  addSingle (state, token) {
    state.list.push(token)
  },
  deleteSingle (state, id) {
    state.list = state.list.filter( o => o.id !== String(id))
  },
  setError (state, error) {
    state.errors = error
  },
  setKeyString (state, keyString) {
    state.keyString = keyString
  }
}

const actions = {
  async add ({ commit }, token) {
    try {
      const data = await request(JWT_ADD, token)
      commit('addSingle', data.jwtAdd.jwtAdded)
      commit('setKeyString', data.jwtAdd.keyString)
      commit('setError', '')
    } catch(e) {
        commit('setError', e.message)
      }
  },
  async delete({ commit }, id) {
    try {
      await request(JWT_DELETE, { id })
      commit('deleteSingle', id)
    } catch(e) {
      commit('setError', e.message)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
