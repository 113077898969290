<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="headline error white--text" primary-title >
        Delete Repository
      </v-card-title>

      <v-card-text>
        <p class="mt-5">
          Are you sure you want to delete the repository <b>{{ name }}</b
          >? This can't be undone.
        </p>
        <v-text-field
            label="Typo repository name to confirm"
            :placeholder="name"
            v-model="userTypedName"
            color='error'
          ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn
          @click="confirmDelete"
          color="error"
          depressed
          :disabled="name !== userTypedName"
          :loading="loading"
        >
          Delete
        <v-icon right >fa-trash-alt</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RepoDeleteDialog",
  data() {
    return {
      dialog: false,
      loading: false,
      confirmSlider: 15,
      id: null,
      name: '',
      userTypedName: ''
    };
  },
  methods: {
    openDeleteDialog(id, name) {
      this.id = id
      this.name = name
      this.dialog = true
      this.userTypedName = ''
    },
    confirmDelete() {
      this.loading = true;
      this.$store.dispatch("repos/delete", this.id).then(() => {
        this.dialog = false;
        this.loading = false;
        this.userTypedName = ''
      });
    },
    cancel() {
      this.dialog = false;
      this.userTypedName = ''
    }
  }
};
</script>

<style scoped></style>
