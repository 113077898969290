import dayjs from 'dayjs'

function formatDateTime (date) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

function formatDate (date) {
  if (date !== null) {
    return dayjs(date).format('YYYY-MM-DD')
  } else {
    return 'N/A'
  }
}

export {formatDateTime, formatDate}
