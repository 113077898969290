<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="text-center mt-5">
        <h1>Get Simple and Secure Backups</h1>
      </v-col>
      <v-col cols="12" sm="8" lg="6">
        <v-alert :value="true" type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-card rounded class="pa-5">
          <form>
            <v-row>
              <v-col cols="12">
                <p>
                  Sign up and receive 10 GB and 2 repositories for free forever.
                  No credit card required. Just a place to keep your backups. 🤩
                </p>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  autofocus
                  label="First Name"
                  v-model="firstName"
                  :error-messages="firstNameErrors"
                  @blur="$v.firstName.$touch()"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  label="Email"
                  v-model="email"
                  :error-messages="emailErrors"
                  type="email"
                  @blur="$v.email.$touch()"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  name="password"
                  :error-messages="passwordErrors"
                  v-model="password"
                  label="Password"
                  type="password"
                  @blur="$v.password.$touch()"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Confirm Password"
                  type="password"
                  :error-messages="confirmPasswordErrors"
                  v-model="passwordConfirm"
                  @blur="$v.passwordConfirm.$touch()"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  color="primary"
                  v-model="privacyAccepted"
                  :error-messages="privacyErrors"
                  @change="$v.privacyAccepted.$touch()"
                >
                  <template v-slot:label>
                    <div>
                      I agree to the
                      <a href="/privacy" target="_blank" @click.stop
                        >privacy policy</a
                      >
                      and
                      <a href="/tos" target="_blank" @click.stop
                        >terms of service</a
                      >.
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  class="primary"
                  dark
                  text
                  type="submit"
                  :loading="loading"
                  @click.prevent="submit"
                >
                  <v-icon left small>fa-user-plus</v-icon>
                  Sign Up
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs
} from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";

export default {
  name: "Register",
  mixins: [validationsMixin],
  data() {
    return {
      firstName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      privacyAccepted: false,
      loading: false,
      error: ""
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid || !this.privacyAccepted) return;
      this.loading = true;
      let user = {
        firstName: this.firstName,
        email: this.email,
        password: this.password
      };
      this.$store
        .dispatch("auth/register", user)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "RegisterConfirmation" });
        })
        .catch(err => {
          this.loading = false;
          this.error = err.message;
        });
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(128)
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    },
    passwordConfirm: {
      required,
      sameAs: sameAs("password")
    },
    privacyAccepted: {
      required
    }
  }
};
</script>

<style scoped></style>
