<template>
  <v-container>
    <v-row>
      <v-col md="12">
    <v-toolbar flat>
      <h1>Your SSH Keys</h1>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        prepend-icon="fa-search"
        label="Filter SSH keys"
        single-line
        hide-details
        color="grey darken-3"
        clearable
        clear-icon="fa-times"
        class="shrink mr-3"
      ></v-text-field>
      <SSHAdd></SSHAdd>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="keys"
      :search="search"
      item-key="id"
      class="elevation-2"
      data-cy="table-ssh"
    >
      <template v-slot:item.keyType="{ item }">
        <code>{{ item.keyType }}</code> ({{ item.bits }} bit)
      </template>
      <template v-slot:item.hashSha256="{ item }">
        <code>{{ item.hashSha256 }}</code>
      </template>
      <template v-slot:item.lastUsedAt="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ item.lastUsedAt | formatDateDistance }}</span>
          </template>
            <span>{{ item.lastUsedAt | formatDateTime }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.addedAt="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ item.addedAt | formatDate }}</span>
          </template>
            <span>{{ item.addedAt | formatDateTime }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.id="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="deleteItem(item.id)">
              <v-icon color="grey" small>fa-trash</v-icon>
            </v-btn>
          </template>
          <span>Delete Key</span>
        </v-tooltip>
      </template>
    </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SSHAdd from "@/components/SSHAdd";
import copy from "copy-to-clipboard";

export default {
  name: "SSHKeys",
  components: {
    SSHAdd
  },
  computed: {
    keys() {
      return this.$store.getters["ssh/list"];
    }
  },
  methods: {
    deleteItem(id) {
      const keyInUse = this.$store.state.repos.list.filter(
        o => o.appendOnlyKeys.includes(id) || o.fullAccessKeys.includes(id)
      )
      if (keyInUse.length > 0) {
        alert("This key is in use. Remove it from repos first.");
      } else {
        this.$store.dispatch("ssh/delete", id);
      }
    },
    copyPath(text) {
      copy(text);
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Type (Length)", value: "keyType", sortable: true, filterable: false, },
        { text: "Fingerprint (SHA256)", value: "hashSha256", sortable: false, filterable: true },
        { text: "Last Used", value: "lastUsedAt", sortable: true, filterable: false, },
        { text: "Added", value: "addedAt", sortable: true, filterable: false, },
        { text: "Actions", value: "id", sortable: false, align: "center" }
      ],
      search: '',
    };
  }
};
</script>

<style scoped></style>
