import request from '@/plugins/graphql'
import SSH_ADD from '@/graphql/users/SSHAdd.graphql'
import SSH_DELETE from '@/graphql/users/SSHDelete.graphql'
import SSH_LIST from '@/graphql/users/SSHList.graphql'

const state = {
  list: [],
  errors: ''
}

const getters = {
  list: state => state.list.sort( (a, b) => a.name.localeCompare(b.name))
}

const mutations = {
  setList (state, repos) {
    state.list = repos
  },
  addSingle (state, key) {
    state.list.push(key)
  },
  deleteSingle (state, id) {
    state.list = state.list.filter(o => o.id !== String(id))
  },
  setError (state, error) {
    state.errors = error
  }
}

const actions = {
  async add ({ commit }, key) {
    try {
      const data = await request(SSH_ADD, key)
      commit('addSingle', data.sshAdd.keyAdded)
      commit('setError', '')
    } catch(e) {
        commit('setError', e.message)
      }
  },
  async delete({ commit }, id) {
    await request(SSH_DELETE, {id})
    commit('deleteSingle', id)
  },
  async list ({ commit }) {
    try {
      const data = await request(SSH_LIST)
      commit('setList', data.sshList)
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
