<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Thanks for signing up!</h1>
      </v-col>
      <v-col cols="12">
        <p>
          You will shortly receive a confirmation email with an activation link.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterConfirmation"
};
</script>

<style scoped></style>
