<template>
  <div>
    <v-btn
      @click.stop="openRepoAddDialog"
      color="primary"
      class="white--text"
      :disabled="!canAddRepo"
      data-cy="btn-add-repo"
    >
      New Repo
      <v-icon right dark>fa-plus-circle</v-icon>
    </v-btn>

    <RepoDialog
      ref="addRepoDialog"
      v-model="addRepoDialogOpen"
      @repoDialogClosedEvent="reactRepoDialogClosed"
    ></RepoDialog>
    <RepoAddSuccessDialog
      :newRepoId="newRepoId"
      v-if="successDialogOpen"
      v-model="successDialogOpen"
    ></RepoAddSuccessDialog>
  </div>
</template>

<script>
import RepoDialog from "@/components/RepoDialog";
import RepoAddSuccessDialog from "@/components/RepoAddSuccessDialog";

export default {
  name: "RepoAddButton",
  components: {
    RepoDialog,
    RepoAddSuccessDialog
  },
  data() {
    return {
      addRepoDialogOpen: false,
      successDialogOpen: false,
      newRepoId: ""
    };
  },
  computed: {
    canAddRepo() {
      return this.$store.getters["repos/reposAvailable"] > 0;
    }
  },
  methods: {
    openRepoAddDialog() {
      this.addRepoDialogOpen = true;
      this.$refs.addRepoDialog.populate("add");
    },
    reactRepoDialogClosed(newRepoId) {
      // newRepoId will be set if a new repo was added
      // (as opposed to cancelling or editing). In that case
      // open the success dialog to show next steps to user.
      if (newRepoId) {
        this.newRepoId = newRepoId;
        this.successDialogOpen = true;
      }
    }
  }
};
</script>
