var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("API Tokens")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" API tokens allow you to use our "),_c('a',{attrs:{"href":"https://docs.borgbase.com/api/","target":"_blank"}},[_vm._v("GraphQL API")]),_vm._v(" without exposing your password or second factor. You can also limit token permissions to your current use case. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Your Active API Tokens")]),_c('v-spacer'),_c('JWTAdd')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tokens,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.permission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.permissionDesc[item.permission].name)+" "),_c('HelpIconWithToolTip',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.permissionDesc[item.permission].desc)+" ")])]}},{key:"item.expiration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expiration))+" ")]}},{key:"item.lastUsed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateDistance")(item.lastUsed))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Token")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }