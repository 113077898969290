var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"usageTable","headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.items.length < 8},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.usedGb",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.usedGb.toFixed(1))+" GB")])]}},{key:"item.includedSize",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s((item.plan.includedSize / 1000).toFixed(0))+" GB ")])]}},{key:"item.excessUsd",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(Number(item.excessUsd).toFixed(4)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }