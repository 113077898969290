var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('h1',[_vm._v("Your SSH Keys")]),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-3",attrs:{"prepend-icon":"fa-search","label":"Filter SSH keys","single-line":"","hide-details":"","color":"grey darken-3","clearable":"","clear-icon":"fa-times"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('SSHAdd')],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.keys,"search":_vm.search,"item-key":"id","data-cy":"table-ssh"},scopedSlots:_vm._u([{key:"item.keyType",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.keyType))]),_vm._v(" ("+_vm._s(item.bits)+" bit) ")]}},{key:"item.hashSha256",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.hashSha256))])]}},{key:"item.lastUsedAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("formatDateDistance")(item.lastUsedAt)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.lastUsedAt)))])])]}},{key:"item.addedAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("formatDate")(item.addedAt)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.addedAt)))])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Key")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }