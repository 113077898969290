
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"endDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plan"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"desc"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxRepos"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxSize"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"includedSize"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"billingExtraGb"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"billingPeriodUsd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"billingPeriodDays"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":218}};
    doc.loc.source = {"body":"fragment SubscriptionFragment on SubscriptionType {\n  startDate\n  endDate\n  plan {\n    id\n    name\n    desc\n    maxRepos\n    maxSize\n    includedSize\n    billingExtraGb\n    billingPeriodUsd\n    billingPeriodDays\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
