<template>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="datetime"
    :sort-desc="true"
    class="elevation-0"
    :hide-default-footer="items.length < 10"
  >
    <template v-slot:item.datetime="{ item }">
      {{ item.datetime | formatDate }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        small
        :color="paymentStatusColors[item.status]"
        text-color="white"
      >
        <v-avatar>
          <v-icon small>{{ paymentStatusIcons[item.status] }}</v-icon>
        </v-avatar>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.total="{ item }">
      {{ item.total.toFixed(2) }}
    </template>
    <template v-slot:item.id="{ item }">
      <a :href="item.id | buildInvoiceURL" target="_blank"
        ><v-icon>fa-file-pdf</v-icon></a
      >
    </template>
    <template v-slot:item.pay="{ item }">
      <router-link
        :to="{ name: 'CheckoutSingle', params: { invoiceId: item.id } }"
        v-if="['open', 'pending'].includes(item.status)"
      >
        <v-icon>fa-shopping-cart</v-icon>
      </router-link>
      <v-icon v-else color="teal">fa-check</v-icon>
    </template>
  </v-data-table>
</template>

<script>
// TODO: view to pay existing invoices.
export default {
  name: "BillingInvoiceHistory",
  data() {
    return {
      headers: [
        { text: "Date", align: "left", value: "datetime" },
        { text: "Status", align: "center", value: "status" },
        { text: "Amount", align: "center", value: "total" },
        { text: "PDF", align: "center", sortable: false, value: "id" },
        { text: "Pay", align: "center", sortable: false, value: "pay" }
      ],
      paymentStatusIcons: {
        draft: "fa-question-circle",
        paid: "fa-check",
        open: "fa-times",
        void: "fa-times",
        refunded: "fa-undo",
        pending: "fa-clock"
      },
      paymentStatusColors: {
        draft: "grey",
        paid: "teal",
        open: "red",
        void: "grey",
        refunded: "orange",
        pending: "orange",
      },
      pagination: { sortBy: "datetime", descending: true, rowsPerPage: 8 }
    };
  },
  computed: {
    items() {
      return this.$store.state.auth.user.invoiceSet.filter(invoice => !['void', 'draft'].includes(invoice.status))
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
