<template>
  <v-container class="pt-0">
    <v-row class="fill-height" justify="center">
      <PricingCard
        planName="Small"
        planPrice="$24/year"
        subtitle="Billed annually, 30 days refund"
        planDesc="Great for freelancers or road warriors with a single laptop."
        includedSize="250"
        maxSize="1000"
        includedRepos="10 Repositories"
        priceExtraSpace=".01"
        sizeUnit="GB"
      ></PricingCard>
      <PricingCard
        planName="Medium"
        planPrice="$80/year"
        subtitle="Billed annually, 30 days refund"
        planDesc="For power users with multiple workstations or a home server."
        includedSize="1"
        maxSize="4"
        sizeUnit="TB"
        includedRepos="Unlimited Repos"
        priceExtraSpace="7"
      ></PricingCard>
      <PricingCard
        planName="Large"
        planPrice="$12.5/month"
        subtitle="Billed $150/year or $15/month"
        planDesc="Perfect for backing up larger servers or a company NAS."
        includedSize="2"
        maxSize="8"
        sizeUnit="TB"
        includedRepos="Unlimited Repos"
        priceExtraSpace="5"
      ></PricingCard>
    </v-row>
  </v-container>
</template>

<script>
import PricingCard from "@/components/PricingCard";

export default {
  name: "PricingTable",
  components: {
    PricingCard
  }
};
</script>

<style scoped>
.pricing-card >>> .plan-price {
  float: right;
  font-weight: bold;
}
.pricing-card >>> h1,
.pricing-card >>> .subprice-large {
  width: 100%;
}
</style>
