import request from '@/plugins/graphql'
import REPO_ADD from '@/graphql/repos/RepoAdd.graphql'
import REPO_EDIT from '@/graphql/repos/RepoEdit.graphql'
import REPO_DELETE from '@/graphql/repos/RepoDelete.graphql'
import REPO_COMPACT from '@/graphql/repos/RepoCompact.graphql'
import REPO_LIST from '@/graphql/repos/RepoList.graphql'

const state = {
  list: [],
  errors: ''
}

const getters = {
  reposAvailable: (state, getters, rootState) => {
    if (!Object.prototype.hasOwnProperty.call(rootState.auth.user, 'activePlan')) return 0
    let maxRepos = rootState.auth.user.activePlan.maxRepos
    if (maxRepos) {
      return maxRepos - getters.repoUsage
    } else {
      return Infinity
    }
  },
  quotaAvailable: (state, getters, rootState) => {
    if (!Object.prototype.hasOwnProperty.call(rootState.auth.user, 'activePlan')) return 0
    let maxSize = rootState.auth.user.activePlan.maxSize
    if (maxSize > 0) {
      return maxSize
    } else {
      return Infinity
    }
  },
  repoList: state => state.list,
  getSingle: state => (id) => state.list.find(i => i.id === id),
  diskUsage: state => state.list.reduce((p, c) => p + c.currentUsage, 0),
  repoUsage: state => state.list.length
}

const mutations = {
  setList (state, repos) {
    state.list = repos
  },
  setPlan (state, plan) {
    state.plan = plan
  },
  addSingle (state, repo) {
    repo.isNew = true
    state.list.push(repo)
  },
  deleteSingle (state, id) {
    state.list = state.list.filter( n => n.id !== String(id))
  },
  updateSingle (state, repo) {
    state.list = [...state.list.filter(i => i.id !== repo.id), repo]
  },
  setError: (state, error) => {
    state.errors = error
  }
}

const actions = {
  async add ({ commit }, repo) {
    try {
      const data = await request(REPO_ADD, repo)
      commit('addSingle', data.repoAdd.repoAdded)
      commit('setError', '')
      return data.repoAdd.repoAdded.id
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async update ({ commit }, repo) {
    try {
      const data = await request(REPO_EDIT, repo)
      commit('updateSingle', data.repoEdit.repoEdited)
      commit('setError', '')
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async delete({ commit }, id) {
      await request(REPO_DELETE, {id})
      commit('deleteSingle', id)
      commit('setError', '')
  },
  async compact({ commit }, id) {
      await request(REPO_COMPACT, {id})
      commit('setError', '')
  },
  async list ({ commit }) {
    try {
      const data = await request(REPO_LIST)
      commit('setList', data.repoList)
    } catch(e) {
      commit('setError', e.message)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
