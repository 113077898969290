import { Decimal } from 'decimal.js'
import request from '@/plugins/graphql'
import BILLING_GET_PLANS from '@/graphql/billing/BillingPlanList.graphql'
import BILLING_INVOICE_CREATE from '@/graphql/billing/BillingInvoiceCreate.graphql'
import BILLING_INVOICE_GET from '@/graphql/billing/BillingInvoiceGet.graphql'
import BILLING_PROCESS_PAYMENT from '@/graphql/billing/BillingProcessPayment.graphql'
import BILLING_VALIDATE_COUPON from '@/graphql/billing/BillingValidateCoupon.graphql'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/browser'

const state = {
  selectedPlan: 2,
  plans: {},
  draftInvoice: null,
  error: '',
  paymentSuccess: false,
  coupon: null,  //{discountPct: 25, forPlans: [2, 3, 4], valid: true, code: 'knfd9d'}
  donationAmount: 0,
  stripePaymentIntentSecret: ''
}

const getters = {
  estimateSubscriptionPeriodEnd(state, getters, rootState) {
    // Add new plan to current plan or start right away, if on trial plan.
    if ( !Object.prototype.hasOwnProperty.call(rootState.auth.user, 'activePlan') ) return 0
    if (rootState.auth.user.activePlan.id !== '5') {
      let currentPlanExpiry = rootState.auth.user.latestSubscription.endDate
      let newPlan = state.plans[state.selectedPlan] || {billingPeriodDays: 0}
      return dayjs(currentPlanExpiry).add(newPlan.billingPeriodDays, 'day')
    } else {
      let newPlan = state.plans[state.selectedPlan] || {billingPeriodDays: 0}
      return dayjs().add(newPlan.billingPeriodDays, 'day')
    }
  },
  totalsFromDraftInvoice(state) {
    if (!state.draftInvoice) return [0,0,0]
    let subTotal = state.draftInvoice.items
      .reduce((accum, item) => accum.plus(item.amount), new Decimal(0))
    let taxAmount =  Decimal.mul(subTotal, state.draftInvoice.taxRate/100)
    let totalWithTax = Decimal.add(subTotal, taxAmount)
    return [subTotal, taxAmount, totalWithTax]
  },
  totalsFromCoupon(state) {
    const plan = state.plans[state.selectedPlan] || {billingPeriodUsd: 0.00}
    const planPrice = plan.billingPeriodUsd
    if (state.coupon) {
      let discountAmount = Decimal.mul(planPrice, state.coupon.discountPct/100)
      let finalAmount = Decimal.sub(planPrice, discountAmount).add(state.donationAmount)
      return [discountAmount, finalAmount]
    } else {
      return [0, Decimal.add(planPrice, state.donationAmount)]
    }
  },
  totalInEUR(state, getters) {
    let exchangeRate = state.draftInvoice["exchangeRate"] || 0.83
    return Number(getters.totalsFromDraftInvoice[2] * exchangeRate).toFixed(2)
  }
}

const mutations = {
  updateSelectedPlan (state, selectedPlan) {
    state.selectedPlan = selectedPlan
  },
  setDraftInvoice (state, draftInvoice) {
    state.draftInvoice = draftInvoice
    state.draftInvoice.items = JSON.parse(draftInvoice.items)
    state.selectedPlan = state.draftInvoice.items[0].plan_id
  },
  reset (state) {
    state.draftInvoice = null
    state.selectedPlan = 2
    state.donationAmount = 0
    state.coupon = null
  },
  setPlans (state, plans) {
    let toSave = {}
    plans.forEach( (elem) => {
      toSave[elem.id] = elem
    } )
    state.plans = toSave
  },
  setCoupon (state, coupon) {
    state.coupon = coupon
  },
  setError (state, error) {
    state.error = error
  },
  setDonation(state, donationAmount) {
    state.donationAmount = donationAmount
  },
  setStripePaymentIntentSecret(state, stripePaymentIntentSecret) {
    state.stripePaymentIntentSecret = stripePaymentIntentSecret
  },
  setPaid(state) {
    state.paymentSuccess = true
  }
}

const actions = {
  async getPlans ({ commit }) {
    const data = await request(BILLING_GET_PLANS)
    commit('setPlans', data.planList)
  },
  async validateCoupon ({ commit }, couponCode) {
    const data = await request(BILLING_VALIDATE_COUPON, {code: couponCode})
      commit('setCoupon', data.validateCoupon)
  },
  async invoiceCreate ({ state, commit }) {
    let vars = {planId: state.selectedPlan, donationAmount: state.donationAmount}
    if (state.coupon) {
      vars.coupon = state.coupon.code
    }
    const data = await request(BILLING_INVOICE_CREATE, vars)
    commit('setDraftInvoice', data.invoiceCreate.invoice)
    commit('setStripePaymentIntentSecret', data.invoiceCreate.stripePaymentIntentSecret)

    // Case of 0-invoice. Mark paid right away.
    if(data.invoiceCreate.invoice.status == 'paid') {
      commit('setPaid')
      commit('auth/addInvoice', data.invoiceCreate.invoice, { root: true })
    }
  },
  async invoiceGet ({ commit }, payload) {
    const data = await request(BILLING_INVOICE_GET, payload)
    commit('setDraftInvoice', data.invoiceGet)
    commit('setStripePaymentIntentSecret', data.stripePaymentIntentSecret)
  },
  async processPayment ({ state, commit }, payload) {
    commit('setError', '');
    payload.invoiceId = state.draftInvoice.id
    payload.data = JSON.stringify(payload.data)
    try {
      const { processPayment } = await request(BILLING_PROCESS_PAYMENT, payload)
      commit('auth/addInvoice', processPayment.newInvoice, { root: true })

      // Update subscriptions and plan.
      if (processPayment.currentSubscription) {
        commit('auth/setUser', {
            activePlan: processPayment.currentSubscription.plan,
            currentSubscription: processPayment.currentSubscription,
            latestSubscription: processPayment.latestSubscription,
        }, { root: true });
        if (payload.gateway != "wire") {
          commit('setPaid');
        }
        if (payload.gateway === 'stripe' && payload.saveCard) {
          commit('auth/setUser', { autoRenew: true }, { root: true })
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      commit('setError', error.message);
      throw error
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
