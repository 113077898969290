<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        v-on="on"
        class="white--text"
        @click.native="loader = 'loading3'"
      >
        New Token
        <v-icon right dark>fa-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">New API Token</span>
      </v-card-title>
      <v-alert :value="true" type="error" v-show="!!this.errors">
        {{ this.errors }}
      </v-alert>
      <v-card-text>
        <v-container>
          <v-row>
            <div v-show="!showNewKey">
              <v-col md="12">
                <p>
                  This token will allow you to use our
                  <a href="https://docs.borgbase.com/api/" target="_blank"
                    >GraphQL API</a
                  >
                  without exposing your password or second factor. Treat your
                  token like a password and remove it after use.
                </p>
                <p><b>You can only view your token once in this dialog.</b></p>
              </v-col>
              <v-col md="12">
                <v-text-field
                  label="Token Name"
                  v-model="name"
                  hint="E.g. Ansible script at XY host"
                  persistent-hint
                  append-outer-icon="fa-pen"
                  spellcheck="false"
                ></v-text-field>
              </v-col>
              <v-col md="12">
                <v-radio-group
                  v-model="permission"
                  label="Permission granted to API Token:"
                >
                  <v-radio value="RO">
                    <template v-slot:label>
                      <span class="mr-1">{{ permissionDesc.RO.name }}</span>
                      <HelpIconWithToolTip>
                        {{ permissionDesc.RO.desc }}
                      </HelpIconWithToolTip>
                    </template>
                  </v-radio>
                  <v-radio value="CO">
                    <template v-slot:label>
                    <span class="mr-1">{{ permissionDesc.CO.name }}</span>
                      <HelpIconWithToolTip class="ml-2">
                        {{ permissionDesc.CO.desc }}
                      </HelpIconWithToolTip>
                    </template>
                  </v-radio>
                  <v-radio value="FA">
                    <template v-slot:label>
                      <span class="mr-1">{{ permissionDesc.FA.name }}</span>
                      <HelpIconWithToolTip class="ml-2">
                        {{ permissionDesc.FA.desc }}
                      </HelpIconWithToolTip>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="expirationDate"
                      label="Expiration Date (optional)"
                      prepend-icon="fa-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expirationDate"
                    no-title
                    scrollable
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </div>
            <div v-show="showNewKey">
              <v-col>
                <h3>Your new API Token</h3>
                <p>
                  Please copy this key to a save place. You can not view it
                  again after closing this dialog.
                </p>
                <pre>{{ keyString }}</pre>
              </v-col>
              <v-col>
                <v-btn
                  class="ma-0"
                  @click.native="copyKeyStringToClipboard"
                  small
                  depressed
                  color="primary"
                >
                  <v-icon left small>fa-copy</v-icon> Copy
                </v-btn>
              </v-col>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="closeDialog"
          >Close</v-btn
        >
        <v-btn
          color="primary darken-1"
          class="white--text"
          :loading="loading"
          :disabled="showNewKey"
          @click.native="add"
        >
          Create Token
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";
import copy from "copy-to-clipboard";

export default {
  name: "RepoAdd",
  components: { HelpIconWithToolTip },
  data() {
    return {
      dialog: false,
      loading: false,
      dateMenu: false,
      showNewKey: false,
      name: "",
      permission: "RO",
      expirationDate: null
    };
  },
  computed: {
    errors() {
      return this.$store.state.jwt.errors;
    },
    keyString() {
      return this.$store.state.jwt.keyString;
    },
    permissionDesc() {
      return this.$store.state.jwt.permissionDesc;
    }
  },
  methods: {
    add() {
      this.loading = true;
      let newToken = {
        name: this.name,
        permission: this.permission,
        expirationDate: this.expirationDate
      };
      this.$store.dispatch("jwt/add", newToken).then(() => {
        if (this.errors) {
          this.loading = false;
        } else {
          this.showNewKey = true;
          this.loading = false;
        }
      });
    },
    closeDialog() {
      this.dialog = false;
      this.name = "";
      this.permission = "RO";
      this.showNewKey = false;
      this.expirationDate = null;
      this.$store.commit("jwt/setKeyString", "");
    },
    copyKeyStringToClipboard() {
      copy(this.keyString);
    }
  }
};
</script>

<style scoped>
pre {
  word-wrap: break-word;
  white-space: normal;
  max-width: 420px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 5px;
}
</style>
