<template>
  <v-row dense class="mt-8">
    <v-col>
      <h2>Email Preferences</h2>
      <v-switch
        mt-0
        color="primary"
        label="Send me a monthly report about my repository activity."
        v-model="receiveMonthlyReport"
        :loading="isLoading"
        @change="updateReminders"
      ></v-switch>
      <v-switch
        mt-0
        color="primary"
        label="Send me a reminder, if there are no active repositories."
        v-model="receiveReminders"
        :loading="isLoading"
        @change="updateReminders"
      ></v-switch>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccountChangeReminders",
  data() {
    return {
      receiveReminders: this.$store.state.auth.user.receiveReminders,
      receiveMonthlyReport: this.$store.state.auth.user.receiveMonthlyReport,
      isLoading: false,
    };
  },
  methods: {
    updateReminders() {
      this.isLoading = true;
      const vars = {
        receiveReminders: this.receiveReminders,
        receiveMonthlyReport: this.receiveMonthlyReport,
      };
      this.$store
        .dispatch("auth/accountUpdate", vars)
        .then(() => {
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.message = err.message;
        });
    }
  }
};
</script>

<style scoped></style>
