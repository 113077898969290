<template>
  <v-data-table
    id="usageTable"
    :headers="headers"
    :items="items"
    class="elevation-0"
    :hide-default-footer="items.length < 8"
  >
    <template v-slot:item.date="{ item }">
      {{ item.date | formatDate }}
    </template>
    <template v-slot:item.usedGb="{ item }">
      <td class="text-right">{{ item.usedGb.toFixed(1) }} GB</td>
    </template>
    <template v-slot:item.includedSize="{ item }">
      <td class="text-right">
        {{ (item.plan.includedSize / 1000).toFixed(0) }} GB
      </td>
    </template>
    <template v-slot:item.excessUsd="{ item }">
      <td class="text-right">${{ Number(item.excessUsd).toFixed(4) }}</td>
    </template>
  </v-data-table>
</template>

<script>
// TODO: view to pay existing invoices.
export default {
  name: "BillingUsageHistory",
  data() {
    return {
      headers: [
        { text: "Date", align: "left", value: "date" },
        { text: "Used GB", align: "center", value: "usedGb" },
        { text: "Included GB", align: "center", value: "includedSize" },
        { text: "Amount", align: "center", value: "excessUsd" }
      ],
      pagination: { sortBy: "datetime", descending: true, rowsPerPage: 8 }
    };
  },
  computed: {
    items() {
      return this.$store.getters["auth/getUsage"];
    }
  },
  mounted() {
    this.$store.dispatch("auth/setUsageList");
  }
};
</script>

<style scoped>
#usageTable >>> td {
  height: 40px;
}
</style>
