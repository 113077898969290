<template>
  <v-row>
    <v-col cols="12">
      <p class="mb-2">
        Subscribe to a new plan or extend an existing plan.
      </p>
      <v-btn class="primary ma-0" dark text to="/checkout"
        >Add or Extend Plan
      </v-btn>
    </v-col>
      <v-col cols="12" class="mt-6">
        <v-alert
        v-show="message"
  type="error"
  dense
>{{ message }}</v-alert>
      <v-switch
        mt-0
        color="primary"
        label="Attempt to automatically extend my subscription"
        hint="Will try to extend your subscription a week before it expires, using your saved credit card.
              You will receive a notification 2 days before the actual charge. Credit card data is
              securely stored with Stripe."
        persistent-hint
        :loading="loading"
        v-model="autoRenew"
        @change="updateAutoRenew()"
      ></v-switch>
      </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SubscriptionChange",
  data() {
    return {
      loading: false,
      autoRenew: this.$store.state.auth.user.autoRenew,
      message: ''
    }
  },
  methods: {
    updateAutoRenew() {
      this.loading = true;
      this.message = ''
      const vars = {
        autoRenew: this.autoRenew,
      };
      this.$store
        .dispatch("auth/accountUpdate", vars)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.message = err.message;
          this.autoRenew = false;
        });
    }
  }
};
</script>

<style scoped></style>
