<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>API Tokens</h2>
      </v-col>
      <v-col cols="12">
        <p>
          API tokens allow you to use our
          <a href="https://docs.borgbase.com/api/" target="_blank"
            >GraphQL API</a
          >
          without exposing your password or second factor. You can also limit
          token permissions to your current use case.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title>Your Active API Tokens</v-toolbar-title>
          <v-spacer></v-spacer>
          <JWTAdd></JWTAdd>
        </v-toolbar>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tokens"
          item-key="id"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDate }}
          </template>
          <template v-slot:item.permission="{ item }">
            {{ permissionDesc[item.permission].name }}
            <HelpIconWithToolTip class="ml-1">
              {{ permissionDesc[item.permission].desc }}
            </HelpIconWithToolTip>
          </template>
          <template v-slot:item.expiration="{ item }">
            {{ item.expiration | formatDate }}
          </template>
          <template v-slot:item.lastUsed="{ item }">
            {{ item.lastUsed | formatDateDistance }}
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="deleteItem(item.id)">
                  <v-icon color="grey" small>fa-trash</v-icon>
                </v-btn>
              </template>
              <span>Delete Token</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JWTAdd from "@/components/JWTAdd";
import copy from "copy-to-clipboard";
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "JWTTable",
  components: {
    JWTAdd,
    HelpIconWithToolTip
  },
  computed: {
    tokens() {
      return this.$store.getters["jwt/list"];
    },
    permissionDesc() {
      return this.$store.state.jwt.permissionDesc;
    }
  },
  methods: {
    deleteItem(id) {
      this.$store.dispatch("jwt/delete", id);
    },
    copyPath(text) {
      copy(text);
    }
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Created", value: "createdAt", sortable: true },
        { text: "Permissions", value: "permission", sortable: true },
        { text: "Expiration Date", value: "expiration", sortable: true },
        { text: "Last Used", value: "lastUsed", sortable: true },
        { text: "Actions", value: "id", sortable: false, align: "center" }
      ]
    };
  }
};
</script>

<style scoped></style>
