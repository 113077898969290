<template>
  <v-row>
    <v-col cols="12">
      <v-list two-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-box</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> {{ activePlan.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ activePlan.desc }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-server</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Repositories</v-list-item-title>
            <v-list-item-subtitle
              >Using {{ this.$store.getters["repos/repoUsage"] }} /
              {{ activePlan.maxRepos || "Unlimited" }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item three-line>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-hdd</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Disk Space</v-list-item-title>
            <v-list-item-subtitle v-if="activePlan.billingExtraGb > 0"
              >Using
              {{ this.$store.getters["repos/diskUsage"] | prettyBytes }} /
              {{ activePlan.includedSize | prettyBytes }} included quota. Up to {{ activePlan.maxSize | prettyBytes }} flexible quota.
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else
              >Used {{ this.$store.getters["repos/diskUsage"] | prettyBytes }} /
              {{ activePlan.includedSize | prettyBytes }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item three-line>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-dollar-sign</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Price</v-list-item-title>
            <v-list-item-subtitle v-if="activePlan.billingPeriodUsd > 0">
              ${{ activePlan.billingPeriodUsd }} for
              {{ activePlan.billingPeriodDays }} days, ${{ activePlan.billingExtraGb }}/GB/month for flexible quota.
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              Free
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-calendar</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Active Subscription</v-list-item-title>
            <v-list-item-subtitle
              v-if="this.$store.getters['auth/hasSubscription']"
            >
              <p>
                Until
                {{ user.latestSubscription.endDate | formatDate }}
              </p>
            </v-list-item-subtitle>
            <v-list-tile-sub-title v-else>
              <p>None, using the free trial plan.</p>
            </v-list-tile-sub-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="12"> </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SubscriptionCurrent",
  computed: {
    activePlan() {
      return this.$store.state.auth.user.activePlan;
    },
    user() {
      return this.$store.state.auth.user;
    }
  }
};
</script>

<style scoped>
small {
  font-size: 70%;
}
</style>
