<template>
  <v-dialog v-model="value" max-width="650px">
    <v-card>
      <v-toolbar flat color="secondary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-alert :value="true" type="error" v-show="!!storeErrors">
        {{ storeErrors }}
      </v-alert>
      <v-container>
        <v-tabs :vertical="$vuetify.breakpoint.width > 600" show-arrows v-model="activeTab">
          <v-tab :key="0">
            <v-icon left>
              fa-columns
            </v-icon>
            Basics
          </v-tab>
          <v-tab :key="1">
            <v-icon left>
              fa-key
            </v-icon>
            Access
          </v-tab>
          <v-tab :key="2">
            <v-icon left>
              fa-heartbeat
            </v-icon>
            Monitoring
          </v-tab>
          <v-tab :key="3" v-show="repo.format.includes('borg')">
            <v-icon left>
              fa-compress-arrows-alt
            </v-icon>
            Compaction
          </v-tab>
          <v-tab :key="4">
            <v-icon left>
              fa-cogs
            </v-icon>
            Advanced
          </v-tab>

          <v-tabs-items v-model="activeTab">
            <v-tab-item :key="0">
              <v-container>
                <v-text-field
                  label="Repository Name"
                  v-model="repo.name"
                  data-cy="input-repo-name"
                  autofocus
                  hint="E.g. server.tld or Macbook Pro Office"
                  persistent-hint
                  append-outer-icon="fa-laptop"
                  @input="$v.repo.name.$touch()"
                  @blur="$v.repo.name.$touch()"
                  :error-messages="repoNameErrors"
                ></v-text-field>

                <v-radio-group
                  :column="privateRegion != null"
                  v-model="repo.region"
                  :disabled="mode != 'add'"
                  append-icon="fa-globe"
                  hint="Region the repository will be hosted in. Can't be changed later."
                  persistent-hint
                  label="Repo Region:"
                  class="mt-8"
                >
                  <v-radio label="EU" value="eu"></v-radio>
                  <v-radio label="US" value="us"></v-radio>
                  <v-radio
                    label="Private"
                    :value="privateRegion"
                    v-show="privateRegion != null"
                  ></v-radio>
                </v-radio-group>

                <v-radio-group
                  :column="false"
                  v-model="repo.format"
                  :disabled="mode != 'add'"
                  append-icon="fa-toolbox"
                  hint="The backup tool to use with this repository. Can't be changed later."
                  persistent-hint
                  label="Repo Format:"
                  class="mt-8"
                >
                  <v-tooltip top max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio label="Borg" value="borg1" v-bind="attrs" v-on="on" />
                    </template>
                    Set up this repository for Borg v1.x. Borg is a mature and trusted backup tool
                    that has been around since 2010. It's written in Python/Cython/C and works over
                    SSH.
                  </v-tooltip>
                  <v-tooltip top max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio label="Restic" value="restic" v-bind="attrs" v-on="on" />
                    </template>
                    <v-chip color="orange" small>Beta</v-chip>
                    Set up this repository for Restic. Restic is younger than Borg and focuses on a
                    more basic feature set. It's written in Go and uses HTTP as transport
                    mechanism, among others.
                  </v-tooltip>
                </v-radio-group>
                <v-alert
                  color="primary"
                  dense
                  text
                  type="info"
                  class="my-5"
                  v-show="repo.format == 'restic'"
                >
                  Restic repos are a new feature. If you have any feedback, please drop us an
                  <a href="mailto:hello@borgbase.com?subject=Restic">email</a>. 🙏
                </v-alert>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1">
              <v-container v-show="repo.format.includes('borg')">
                <p class="text-body-2">
                  SSH keys are used to access the repository securely without a password. You can
                  assign multiple keys with different permissions.
                  <HelpIconWithToolTip>
                    <p>
                      Append-only keys can <b>not</b> clean up old data and any delete- or prune
                      operation they do can be undone until the repository is compacted or written
                      to with a full-access key.
                    </p>
                    <p>
                      Full-access keys can do everything, including cleaning up of old data.
                    </p>
                  </HelpIconWithToolTip>
                  Manage your account's SSH keys
                  <router-link :to="{ name: 'SSHKeys' }"> here </router-link>.
                </p>
                <v-autocomplete
                  :items="availableKeys('rsyncKeys', 'appendOnlyKeys')"
                  append-outer-icon="fa-key"
                  auto-select-first
                  chips
                  deletable-chips
                  hint="Full Access: These keys can read, add and delete files."
                  item-text="name"
                  item-value="id"
                  multiple
                  persistent-hint
                  placeholder="Keys with Full Access"
                  small-chips
                  v-model="repo.fullAccessKeys"
                ></v-autocomplete>
                <v-autocomplete
                  :items="availableKeys('rsyncKeys', 'fullAccessKeys')"
                  append-outer-icon="fa-ban"
                  :disabled="repo.sftpEnabled"
                  auto-select-first
                  chips
                  deletable-chips
                  hint="Append-Only Access: These keys can't clean up old data."
                  item-text="name"
                  item-value="id"
                  multiple
                  persistent-hint
                  placeholder="Keys with Append-Only Access"
                  small-chips
                  v-model="repo.appendOnlyKeys"
                ></v-autocomplete>
                <v-autocomplete
                  v-if="['3971', '4699'].includes(this.$store.state.auth.user.id)"
                  :items="availableKeys('appendOnlyKeys', 'fullAccessKeys')"
                  append-outer-icon="fa-sync-alt"
                  auto-select-first
                  chips
                  deletable-chips
                  hint="Rsync Access: These keys can run rsync"
                  item-text="name"
                  item-value="id"
                  multiple
                  persistent-hint
                  placeholder="Keys with Rsync Access"
                  small-chips
                  v-model="repo.rsyncKeys"
                ></v-autocomplete>
              </v-container>
              <v-container v-show="repo.format.includes('restic')">
                <p class="text-body-2">
                  Connect to your Restic repo using HTTPS with basic auth. To copy the full path,
                  use the <v-icon small>fa-copy</v-icon> button in the repository table.
                </p>
                <p class="text-body-2">
                  In addition, you can set a Restic repository to append-only mode. This will
                  prevent the <code>restic forget</code> command from working and any deletions
                  will fail with an error.
                </p>
                <v-switch
                  v-model="repo.appendOnly"
                  label="Enable Append-only mode"
                  inset
                ></v-switch>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="2">
              <v-container>
                <p class="text-body-2">
                  Backup processes can fail for many reasons. With monitoring enabled, we will
                  alert you of stale backups.
                </p>
                <v-select
                  :items="alertDaysItems"
                  v-model="repo.alertDays"
                  item-text="label"
                  item-value="value"
                  label="Monitoring"
                  hint="Alert me after X days without new backup in this repo."
                  persistent-hint
                  dense
                  append-outer-icon="fa-clipboard-check"
                  class="my-8"
                ></v-select>
                <p class="text-body-2">
                  You can manage alert channels in
                  <router-link :to="{ name: 'Account', query: { tab: 1 } }">
                    Account > Alerts
                  </router-link>
                </p>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="3">
              <v-container>
                <p class="text-body-2">
                  When keeping your repository in append-only (delayed deletion) mode, old file
                  segments aren't removed right away and can be recovered. This can increase the
                  repo size over time. You can enable server-side compaction to clean up old data.
                  <HelpIconWithToolTip>
                    Keep in mind that data marked for deletion (e.g. pruned archives) will be
                    irreversibly lost after a compaction run. So we recommend a compaction interval
                    of no less than 6 to 8 weeks to allow for rollbacks.
                  </HelpIconWithToolTip>
                </p>
                <v-switch
                  v-model="repo.compactionEnabled"
                  label="Enable server-side compaction"
                  inset
                ></v-switch>
                <v-row align="center" dense>
                  <v-col cols="4">
                    Compact every
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :disabled="!repo.compactionEnabled"
                      v-model="repo.compactionInterval"
                      :items="compactionIntervalItems"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :disabled="!repo.compactionEnabled"
                      v-model="repo.compactionIntervalUnit"
                      :items="compactionIntervalUnitItems"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row align="center" dense>
                  <v-col cols="3">
                    at around
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :disabled="!repo.compactionEnabled"
                      v-model="repo.compactionHour"
                      :items="compactionHourItems"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    local time ({{ localTimezone }})
                    <HelpIconWithToolTip>
                      Choose an hour of the day, when the repository is likely <b>not in use</b>.
                      The repository will be locked during compaction. If the repo is in use during
                      compaction, it fail and get retried the next day. You can review successful
                      compaction runs in the repo's logs.
                    </HelpIconWithToolTip>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="4">
              <v-container>
                <v-row v-show="repo.format.includes('borg')">
                  <v-col>
                    <p class="text-body-2">
                      Access the repo via SFTP. This is mostly useful to
                      <a href="https://docs.borgbase.com/setup/import/" target="_blank"
                        >import, export or edit</a
                      ><v-icon x-small right>fa-external-link-alt</v-icon> raw repository data.
                      When enabled, only <em>full access</em> keys can access the repo.
                    </p>
                    <v-switch
                      v-model="repo.sftpEnabled"
                      label="Enable SFTP access"
                      inset
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row v-show="repo.format.includes('borg')" class="mb-5">
                  <v-col md="12">
                    <p class="text-body-2">
                      Borg version to use for this repository. Setting 'Latest'
                      will always keep you at the latest stable version.
                    </p>
                    <v-select
                      :items="borgVersions"
                      v-model="repo.borgVersion"
                      item-text="label"
                      item-value="value"
                      hint="BorgBackup Version"
                      persistent-hint
                      dense
                      append-outer-icon="fa-code-branch"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <p class="text-body-2 mb-0 pb-0">
                      Set a hard storage limit for this repository. You will be notified when space
                      runs out.
                    </p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6" id="quota-buttons">
                    <v-checkbox
                      label="Enable Storage Limit"
                      v-model="repo.quotaEnabled"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="!repo.quotaEnabled"
                      v-model="repo.quota"
                      type="number"
                      suffix="GB"
                      label="Quota"
                      append-outer-icon="fa-hdd"
                      hint="Larger than current usage for existing repos."
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
      <v-divider light></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('input', false)">Cancel</v-btn>
        <v-btn
          color="primary"
          depressed
          dark
          :loading="loading"
          data-cy="btn-add-repo-submit"
          @click="submit"
        >
          {{ title }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref, reactive } from "@vue/composition-api";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";
import store from "@/store";
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "RepoDialog",
  props: ["value"],
  components: {
    HelpIconWithToolTip
  },
  mixins: [validationsMixin],
  validations: {
    repo: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(64)
      }
    }
  },
  setup(_, { emit }) {
    const { privateRegion, storeErrors, createRepo, localTimezone } = useRepoFormHelpers();

    const {
      alertDaysItems,
      borgVersions,
      compactionIntervalItems,
      compactionIntervalUnitItems,
      compactionHourItems
    } = useRepoFormConstants();

    const mode = ref("add"),
      title = ref(""),
      loading = ref(false),
      advancedPanel = ref([]),
      repo = ref(createRepo()),
      activeTab = ref(0);

    // const { dirty, valid, errors } = useValidation(repo, validationRules)

    function populate(_mode, repoId) {
      advancedPanel.value = [];
      mode.value = _mode;
      activeTab.value = 0;
      this.$v.$reset();
      store.commit("repos/setError", "");
      if (_mode === "add") {
        repo.value = createRepo();
        title.value = "Add Repository";
      } else {
        repo.value = createRepo(repoId);
        title.value = "Edit Repository";
      }
    }

    function availableKeys(ex1, ex2) {
      return store.getters["ssh/list"].filter(
        o => !(repo.value[ex1].includes(o.id) || repo.value[ex2].includes(o.id))
      );
    }

    async function submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      loading.value = true;

      const _repo = { ...repo.value };
      _repo.quota = _repo.quota * 1000; // UI uses GB, backend MB
      _repo.compactionHourTimezone = this.localTimezone;
      const newRepoId = await store.dispatch(`repos/${mode.value}`, _repo);
      loading.value = false;
      if (!storeErrors.value) {
        emit("repoDialogClosedEvent", newRepoId);
        emit("input", false);
      }
      advancedPanel.value = [];
    }

    return {
      alertDaysItems,
      borgVersions,

      populate,
      submit,
      availableKeys,
      privateRegion,
      localTimezone,

      title,
      advancedPanel,
      mode,
      repo,
      loading,
      storeErrors,
      activeTab,
      compactionIntervalItems,
      compactionIntervalUnitItems,
      compactionHourItems
    };
  }
};

function useRepoFormConstants() {
  return {
    alertDaysItems: [
      { value: 0, label: "Disable Alerts" },
      { value: 1, label: "After 1 day" },
      { value: 2, label: "After 2 days" },
      { value: 5, label: "After 5 days" },
      { value: 7, label: "After 7 days" },
      { value: 10, label: "After 10 days" },
      { value: 14, label: "After 14 days" },
      { value: 30, label: "After 30 days" }
    ],
    borgVersions: [
      { value: "LATEST", label: "Latest stable" },
      { value: "V_1_1_X", label: "1.1.x" },
      { value: "V_1_2_X", label: "1.2.x" },
      { value: "V_2_0_X", label: "2.0 Beta (Testing only!)" }
    ],
    validationRules: {
      name: "required|between:1,32"
    },
    compactionIntervalItems: [...Array(24).keys()].map(v => 1 + v),
    compactionIntervalUnitItems: ["days", "weeks", "months"],
    compactionHourItems: [...Array(24).keys()].map(v => {
      return { text: `${v}:00`, value: v };
    })
  };
}

function useRepoFormHelpers() {
  const createRepo = id => {
    if (id) {
      const existing = store.getters["repos/getSingle"](id);
      return reactive({
        id: id,
        name: existing.name,
        quota: existing.quota / 1000, // MB to GB
        quotaEnabled: existing.quotaEnabled,
        appendOnlyKeys: existing.appendOnlyKeys,
        fullAccessKeys: existing.fullAccessKeys,
        rsyncKeys: existing.rsyncKeys,
        region: existing.region,
        borgVersion: existing.borgVersion,
        alertDays: existing.alertDays,
        sftpEnabled: existing.sftpEnabled,
        appendOnly: existing.appendOnly,
        format: existing.format,
        compactionEnabled: existing.compactionEnabled,
        compactionInterval: existing.compactionInterval,
        compactionIntervalUnit: existing.compactionIntervalUnit,
        compactionHour: existing.compactionHour
      });
    } else {
      return reactive({
        name: id,
        quota: 10, // in GB
        alertDays: 0,
        quotaEnabled: false,
        borgVersion: "LATEST",
        appendOnlyKeys: [],
        fullAccessKeys: [],
        rsyncKeys: [],
        region: store.state.auth.user.privateRegion || "eu",
        sftpEnabled: false,
        appendOnly: false,
        format: "borg1",
        compactionEnabled: false,
        compactionInterval: 6,
        compactionIntervalUnit: "weeks",
        compactionHour: 14
      });
    }
  };

  const privateRegion = computed(() => store.state.auth.user.privateRegion);
  const storeErrors = computed(() => store.state.repos.errors);
  const localTimezone = computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone);

  return {
    createRepo,
    privateRegion,
    storeErrors,
    localTimezone
  };
}
</script>

<style scoped>
.v-expansion-panel >>> .v-expansion-panel__header {
  padding: 0 !important;
}
.v-input--radio-group >>> legend,
.v-input--radio-group >>> label {
  margin-right: 10px;
}
.v-select.v-select--chips .v-select__selections {
  min-height: auto;
}
.v-tab {
  justify-content: left;
}
</style>
