import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'
import colors from 'vuetify/lib/util/colors'
import BorgBackupLogo from '@/partials/BorgBackupLogo'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      borgbackup: { // name of our custom icon
        component: BorgBackupLogo, // our custom component
      },
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.orange,
        secondary: colors.blueGrey.darken3,
        accent: colors.grey.lighten2,
        stripeText: colors.grey.darken4
      },
      dark: {
        primary: colors.orange.darken2,
        secondary: colors.blueGrey.darken4,
        accent: colors.grey.darken2,
        stripeText: colors.grey.lighten5
      },
    },
  }
});
