import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import repos from './repos.module'
import ssh from './ssh.module'
import jwt from './jwt.module'
import checkout from './checkout.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    repos,
    ssh,
    jwt,
    checkout,
  }
})
