var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Your Backup Repositories")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{staticClass:"elevation-2 mb-1",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" Current Usage: "),_c('small',{staticClass:"font-weight-bold"},[_c('v-badge',{attrs:{"dot":"","color":_vm.diskUsageColor}},[_vm._v(_vm._s(_vm._f("prettyBytes")(_vm.diskUsage)))])],1)])]}}])},[_c('span',[_vm._v(" Your plan includes "+_vm._s(_vm._f("prettyBytes")(_vm.user.activePlan.includedSize))+" with a flexible quota of up to "+_vm._s(_vm._f("prettyBytes")(_vm.user.activePlan.maxSize))+". ")])]),_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"text":"","dark":"","icon":"","small":"","color":"grey","to":{ name: 'Account', query: { tab: 0 } }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-pen")])],1)]}}])},[_c('span',[_vm._v(" View details or make changes to your plan. ")])])],1),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink d-none d-md-inline-flex",attrs:{"prepend-icon":"fa-search","label":"Filter repositories","single-line":"","hide-details":"","color":"grey darken-3","clearable":"","clear-icon":"fa-times"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":_vm.refreshRepos}},on),[_c('v-icon',[_vm._v("fa-sync-alt")])],1)]}}])},[_c('span',[_vm._v("Refresh repo details")])]),_c('RepoAddButton')],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.repos,"item-key":"id","expanded":_vm.expanded,"search":_vm.search,"sort-by":"name","single-expand":"","footer-props":{
          'items-per-page-options': [20, 30, 50, 80, 100, -1]
        },"items-per-page":30,"data-cy":"table-repos","id":"repo-table"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":""},on:{"click":function($event){return _vm.copyPath(item.repoPath, item.name)}}},on),[_c('v-icon',{attrs:{"color":item.isNew ? 'primary' : 'grey',"small":""}},[_vm._v("fa-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to copy repo path: "),_c('pre',[_vm._v(_vm._s(item.repoPath))])])]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.currentUsage",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyBytes")(item.currentUsage))+" "),(item.quotaEnabled)?_c('v-tooltip',{attrs:{"top":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-progress-circular',_vm._g({attrs:{"value":(item.currentUsage / item.quota) * 100,"width":5,"size":25,"color":"primary"}},on))]}}],null,true)},[_c('span',[_vm._v("Assigned Quota: "+_vm._s(_vm._f("prettyBytes")(item.quota))+". If you exceed your quota during a backup, leftover data will be removed automatically during the next repo-write operation.")])]):_vm._e()]}},{key:"item.keys",fn:function(ref){
        var item = ref.item;
return [_c('KeyChipsWithMoreIcon',{attrs:{"fullAccessKeys":item.fullAccessKeys,"appendOnlyKeys":item.appendOnlyKeys}}),(item.format === 'restic')?_c('span',{staticClass:"text-caption"},[_vm._v("Not used for Restic")]):_vm._e()]}},{key:"item.region",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_c('b',[_vm._v(_vm._s(item.region.toUpperCase()))])])]}}],null,true)},[_c('span',[_vm._v("Located on "),_c('code',[_vm._v(_vm._s(item.server.hostname))]),_vm._v(" in "+_vm._s(item.server.location))])])]}},{key:"item.format",fn:function(ref){
        var item = ref.item;
return [(item.format.includes('borg'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v("$borgbackup")])]}}],null,true)},[_vm._v(" Uses Borg Backup ")]):_vm._e(),(item.format.includes('restic'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":"","color":"blue"}},on),[_vm._v("fa-umbrella")])]}}],null,true)},[_vm._v(" Uses Restic ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(" "+_vm._s(item.encryption != "none" ? "fa-lock" : "fa-lock-open")+" ")])]}}],null,true)},[_c('span',[_vm._v("Encryption: "),_c('pre',[_vm._v(_vm._s(item.encryption))])])]),(item.format.includes('restic') && item.appendOnly)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v("fa-ban")])]}}],null,true)},[_vm._v(" This repository is in append-only mode. No data can be removed. ")]):_vm._e(),(item.format.includes('borg'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v("fa-fingerprint")])]}}],null,true)},[_c('span',[_vm._v("SSH Host Key Fingerprints:"),_c('br'),_vm._v(" RSA: "),_c('code',[_vm._v(_vm._s(item.server.fingerprintRsa))]),_c('br'),_vm._v(" ED25519: "),_c('code',[_vm._v(_vm._s(item.server.fingerprintEd25519))]),_c('br'),_vm._v(" ECDSA: "),_c('code',[_vm._v(_vm._s(item.server.fingerprintEcdsa))])])]):_vm._e(),(item.sftpEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":"","color":"error"}},on),[_vm._v("fa-folder-open")])]}}],null,true)},[_vm._v(" SFTP is enabled for this repository. ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.compactionEnabled),expression:"item.compactionEnabled"}],attrs:{"small":"","left":""}},on),[_vm._v("fa-compress-arrows-alt")])]}}],null,true)},[_vm._v(" Compactions are scheduled to run every "+_vm._s(item.compactionInterval)+" "+_vm._s(item.compactionIntervalUnit)+" at "+_vm._s(item.compactionHour)+":00 local time. ")])]}},{key:"item.lastModified",fn:function(ref){
        var item = ref.item;
return [(item.lastModified)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("formatDateDistance")(item.lastModified)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.lastModified)))])]):_c('span',[_vm._v("Never")])]}},{key:"item.alertDays",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-badge',{attrs:{"dot":"","color":"red darken-2","value":_vm.repoHasOutdatedModification(item)}},[_vm._v(" "+_vm._s(item.alertDays > 0 ? item.alertDays + " days" : "Disabled"))])],1)]}}],null,true)},[(_vm.repoHasOutdatedModification(item))?_c('span',[_vm._v(" This repo wasn't modified within the set monitoring time frame. ")]):_c('span',[_vm._v(" You will receive an email alert if no new backups are detected. ")])])]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 mr-0 pl-0",attrs:{"icon":""},on:{"click":function($event){return _vm.openRepoEditDialog(item.id)}}},on),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("fa-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 mr-0",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey","small":""},on:{"click":function($event){return _vm.expandRepoTable(item, 'RepoTableExpandLog')}}},[_vm._v("fa-list")])],1)]}}],null,true)},[_c('span',[_vm._v("Event Log")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-0 mr-0",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey","small":""},on:{"click":function($event){return _vm.expandRepoTable(item, 'RepoTableExpandUsage')}}},[_vm._v("fa-chart-bar")])],1)]}}],null,true)},[_c('span',[_vm._v("Usage")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("fa-caret-square-down")])],1)]}}],null,true)},[_c('span',[_vm._v("More...")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.format.includes('borg')),expression:"item.format.includes('borg')"}],on:{"click":function($event){return _vm.compactRepo(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("fa-compress-arrows-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Compact repo")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.repoDeleteDialog.openDeleteDialog(item.id, item.name)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("fa-trash")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete repo")])],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.expandComponent,{tag:"component",attrs:{"repoId":item.id}},[_vm._v("hello")])],1)]}}])})],1)],1),_c('RepoDialog',{ref:"repoEditDialog",on:{"repoDialogClosedEvent":function($event){_vm.repoEditDialogOpen = false}},model:{value:(_vm.repoEditDialogOpen),callback:function ($$v) {_vm.repoEditDialogOpen=$$v},expression:"repoEditDialogOpen"}}),_c('RepoDeleteDialog',{ref:"repoDeleteDialog"}),_c('v-snackbar',{attrs:{"timeout":"5000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.feedbackSnackBarOpen = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.feedbackSnackBarOpen),callback:function ($$v) {_vm.feedbackSnackBarOpen=$$v},expression:"feedbackSnackBarOpen"}},[_vm._v(" "+_vm._s(_vm.feedbackSnackBarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }