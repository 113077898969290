import request from '@/plugins/graphql'
import REGISTER from '@/graphql/users/AuthRegister.graphql'
import LOGIN from '@/graphql/users/AuthLogin.graphql'
import LOGOUT from '@/graphql/users/AuthLogout.graphql'
import ACCOUNT_DETAILS from '@/graphql/users/AuthAccount.graphql'
import ACCOUNT_UPDATE from '@/graphql/users/AuthAccountUpdate.graphql'
import CHANGE_PASSWORD from '@/graphql/users/AuthChangePassword.graphql'
import ALERT_UPDATE from '@/graphql/users/AlertUpdate.graphql'
import AUTH_REFRESH from '@/graphql/users/AuthRefresh.graphql'
import AUTH_SAVE_REFERER from '@/graphql/log/AuthSaveReferer.graphql'
import BILLING_DETAILS_UPDATE from '@/graphql/users/BillingDetailsUpdate.graphql'
import BILLING_OVERAGE_LIST from '@/graphql/billing/BillingOverageList.graphql'
import USER_REMOVE from '@/graphql/users/UserRemove.graphql'

import router from '@/router'

const state = {
  user: {},
  error: '',
  logoutTimer: null,
  usage: [],
  accruedOverage: 0.0
}

const getters = {
  isAuthenticated: state => {
    return Object.prototype.hasOwnProperty.call(state.user, 'id')
  },
  getUser: state => {
    return state.user
  },
  hasSubscription (state) {
    if ( Object.prototype.hasOwnProperty.call(state.user, 'activePlan') ) {
      return state.user.activePlan.id !== '5'
    } else {
      return false
    }
  },
  getUsage: state => {
    return state.usage
  },
  getAccruedOverage: state => {
    return state.accruedOverage
  }
}

const mutations = {
  setUser (state, userNew) {
    if ( Object.prototype.hasOwnProperty.call(userNew, 'alerts') && typeof userNew.alerts === 'string' ) {
      userNew.alerts = JSON.parse(userNew.alerts)
    }
    state.user = {...state.user, ...userNew}
  },
  clearUser (state) {
    state.user = {}
  },
  setError (state, error) {
    state.error = error
  },
  addInvoice(state, newInvoice) {
    const exists = state.user.invoiceSet.filter(o => o.id == newInvoice.id)
    if (exists.length === 0) {
      state.user.invoiceSet.push(newInvoice)
    } else {
      let ix = state.user.invoiceSet.indexOf(exists[0])
      state.user.invoiceSet[ix] = newInvoice
    }
  },
  setUsage(state, usageList) {
    state.usage = usageList
  },
  setAccruedOverage(state, amount) {
    state.accruedOverage = amount
  }
}

const actions = {
  async register (_, user) {
    await request(REGISTER, user)
  },
  async accountDetails ({ commit, getters }) {
      if (getters['isAuthenticated']) {
        return
      }
      try {
        const {me: user} = await request(ACCOUNT_DETAILS)
        commit('setUser', user)
        commit('repos/setList', user.repoSet,  { root: true })
        commit('ssh/setList', user.publicsshkeySet,  { root: true })
        commit('jwt/setList', user.jwttokenSet,  { root: true })
        commit('setError', '')
      } catch {
        return
      }
  },
  async accountUpdate ({ commit }, vars) {
    const data = await request(ACCOUNT_UPDATE, vars)
    commit('setUser', data.userUpdate.user)
  },
  async billingDetailsUpdate ({ commit }, vars) {
    const data = await request(BILLING_DETAILS_UPDATE, vars)
    commit('setUser', data.userUpdate.user)
  },
  async alertUpdate ({ commit }, vars) {
    const data = await request(ALERT_UPDATE, vars)
    commit('setUser', data.alertUpdate.alerts)
  },
  async login ({ commit, dispatch }, vars) {
      const { login: {user, rememberDeviceToken} } = await request(LOGIN, vars)
      commit('setUser', user)
      commit('repos/setList', user.repoSet,  { root: true })
      commit('ssh/setList', user.publicsshkeySet,  { root: true })
      commit('jwt/setList', user.jwttokenSet,  { root: true })
      commit('setError', '')
      localStorage.setItem('rememberDeviceToken', rememberDeviceToken)
      dispatch('resetLogoutTimer')
  },
  async logout ({ commit }) {
      await request(LOGOUT)
      commit('clearUser')
      commit('repos/setList', [],  { root: true })
      commit('repos/setPlan', {},  { root: true })
      commit('ssh/setList', [],  { root: true })
      commit('jwt/setList', [],  { root: true })
      commit('setError', '')
  },
  sessionExpired ({ commit, getters }) {
      if (getters['isAuthenticated']) {
        commit('clearUser')
        commit('repos/setList', [],  { root: true })
        commit('repos/setPlan', {},  { root: true })
        commit('ssh/setList', [],  { root: true })
        commit('jwt/setList', [],  { root: true })
        commit('setError', 'Session has expired.')
        router.push('/login').catch(()=>{})
      }
  },
  async refreshSession ({getters}) {
    if (getters['isAuthenticated']) {
      await request(AUTH_REFRESH).catch( () => {
        console.log('Not authenticated')
      })
    }
  },
  resetLogoutTimer ({state, dispatch, getters}) {
    if (state.logoutTimer !== null) {
      window.clearTimeout(state.logoutTimer)
    }
    if (getters['isAuthenticated']){
      state.logoutTimer = window.setTimeout(()=>{
        dispatch('sessionExpired')
      }, 30*60*1000)
    }
  },
  passwordChange(_, vars) {
     return request(CHANGE_PASSWORD, vars)
  },
  userRemove(_, vars) {
     return request(USER_REMOVE, vars)
  },
  async saveReferer () {
    let vars = {
      path: router.currentRoute.path,
      utmCampaign: router.currentRoute.query.utm_campaign,
      utmMedium: router.currentRoute.query.utm_medium,
      utmSource: router.currentRoute.query.utm_source,
      referer: document.referrer,
      screenHeight: screen.height,
      screenWidth: screen.width,
      language: navigator.languages[0]
    }
    await request(AUTH_SAVE_REFERER, vars)
  },
  async setUsageList ({ commit }) {
    const data = await request(BILLING_OVERAGE_LIST)
    commit('setUsage', data.overageList)
    commit('setAccruedOverage', data.accruedOverage)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
