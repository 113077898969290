<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        class="white--text"
        @click.native="loader = 'loading3'"
        data-cy='btn-ssh-add'
      >
        Add Key
        <v-icon right dark>fa-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add SSH Key</span>
      </v-card-title>
      <v-alert :value="true" type="error" v-show="!!this.errors">
        {{ this.errors }}
      </v-alert>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col md="12">
              <p>
                Repos are accessed using a public/private pair of SSH keys. To
                get started you need to upload the public key here. You will
                then be able to connect to repos using the private part of the
                key, which remains on your machine.
              </p>
              <p>
                For more details on how to generate a public key in OpenSSH format,
                see our <a href="https://docs.borgbase.com/faq/ssh/" target="_blank">
                docs</a>.
              </p>
            </v-col>
            <v-col md="12">
              <v-textarea
                v-model="keyData"
                flat
                label="Public Key (OpenSSH Format)"
                placeholder="ssh-ed25519 AAAAB3NzaXXXXNrRFi9wrf+M7Q== user@example.com"
                @change="setKeyNameFromKeyBody"
                spellcheck="false"
                data-cy="input-ssh-data"
                :error-messages="keyDataErrors"
                @input="$v.keyData.$touch()"
                @blur="$v.keyData.$touch()"
              ></v-textarea>
              <v-text-field
                label="Key Name"
                v-model="name"
                hint="E.g. user@example.com"
                persistent-hint
                append-outer-icon="fa-fingerprint"
                spellcheck="false"
                data-cy="input-ssh-name"
                :error-messages="nameErrors"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.native="cancelDialog"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          dark
          :loading="loading"
          @click.native="add"
          data-cy="btn-ssh-add-submit"
        >
          Add Key
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";
const openSshKeyFormat = helpers.regex('opensshkey',
    /^(ssh-rsa|ecdsa-sha2-nistp256|ecdsa-sha2-nistp521|ssh-ed25519) [0-9A-Za-z+/]+[=]{0,3}( .*)?(\r\n|\r|\n)*$/
  )

export default {
  name: "RepoAdd",
  mixins: [validationsMixin],
  data() {
    return {
      dialog: false,
      loading: false,
      name: "",
      keyData: ""
    };
  },
  computed: {
    errors() {
      return this.$store.state.ssh.errors;
    },
    nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Please enter a name for your key.')
        return errors
      },
    keyDataErrors () {
        const errors = []
        if (!this.$v.keyData.$dirty) return errors
        !this.$v.keyData.required && errors.push('Please enter a public key in OpenSSH format.')
        !this.$v.keyData.openSshKeyFormat && errors.push('Ensure you enter a public key in OpenSSH format.')
        return errors
      },
  },
  methods: {
    add() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      let newKey = {
        name: this.name,
        keyData: this.keyData
      };
      this.$store.dispatch("ssh/add", newKey).then(() => {
        if (this.errors) {
          this.loading = false;
        } else {
          this.dialog = false;
          this.name = "";
          this.keyData = "";
          this.loading = false;
          this.$v.$reset();
        }
      });
    },
    cancelDialog() {
      this.name = "";
      this.keyData = "";
      this.dialog = false;
      this.$v.$reset();
    },
    setKeyNameFromKeyBody() {
      if (this.name.length == 0) {
        let keyComment = this.keyData.split(" ").slice(2);
        this.name = keyComment.join(" ");
      }
    }
  },
  validations: {
    keyData: { required, openSshKeyFormat },
    name: { required },
  }
};
</script>

<style scoped>
.v-textarea >>> textarea {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin-top: 5px;
  font-family: monospace;
  font-size: 12px;
}
</style>
